<template>
  <div>
    <div class="row-3">
      <div class="row-3-col-1" id="listVideo">
        <div class="card video-list">
          <div class="body">
            <div class="tabs p-4">
              <div class="tab-item"
                   :class="{'active': typeChart}"
                   @click="changeTypeChart(true)"
                   data-compare-report="action"
              >総リアクション</div>
              <div class="tab-split"></div>
              <div class="tab-item"
                   :class="{'active': !typeChart}"
                   @click="changeTypeChart(false)"
                   data-compare-report="action"
              >各リアクション</div>
            </div>
            <div class="list-checkbox">
              <div 
                v-for="item in avgs"
                :key="item.id"
                class="list-item"
                :id="item.id"
                :class="{ 'active selected': selectedSeriesId == item.id }"
              >
                <i class="icon icon-checkbox"
                   data-compare-report="action"
                  :class="{ checked: shownReportIds.find(e => e == item.id)}"
                  @click="shownAvg(item),getAvgId(item)"></i>
                <div class="item-name pl-2" @click="selectedVideo(item.id)" data-compare-report="action">
                  平均値（{{ item.name }}）
                </div>
              </div>
              <div
                class="list-item item-compare"
                :class="{
                  'active selected': selectedSeriesId == 'video-' + video.id,
                }"
                v-for="(video, index) in videos"
                :key="'video-' + index"
                :id="'video-' + video.id"
              >
                <div class="item">
                  <i
                    class="icon icon-checkbox"
                    :class="{ checked: video.checked }"
                    @click="checkedVideo(video)"
                    data-compare-report="action"
                  ></i>
                  <div class="item-name pl-2" @click="selectedVideo(video)" data-compare-report="action">
                    <span class="item-time">
                      {{ video.start_at | moment("YYYY/M/DD") }} {{video.video_length}}s</span
                    >
                    {{ video.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div id="hiddenList" @click="hideListVideo" data-compare-report="action">
              リスト非表示
            </div>
            <div class="settingAvg" @click="showSettingModal = true">
              <i class="icon icon-small-setting mr-1"></i>
              <div>平均値設定</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-3-col-2 chart-area" id="chartArea">
        <button-option
            v-if="!typeChart && interactionButtonColor.rank_2 && interactionButtonColor.rank_3"
            :reactionButton="reactionButton"
            @changeReactionButton="handleChangeReactionButton"
            :isShowListVideo="isShowListVideo"
        >
        </button-option>
        <step-option
          :options="stepOptions"
          :selected="step"
          :eventName="changeStepOption"
        ></step-option>
        <div class="title-y-axis">%</div>
        <div class="title-x-axis">Time</div>
        <i class="icon icon-prev" v-if="prevPartEnabled" @click="prevPart" data-compare-report="action"></i>
        <i class="icon icon-next" v-if="nextPartEnabled" @click="nextPart" data-compare-report="action"></i>
        <div v-if="interactionButtonColor.rank_2 && interactionButtonColor.rank_3">
            <line-chart
            v-if="typeChart"
            @setChart="getChart"
            :part="part"
            :dataReport="dataReport"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :selectedId="selectedSeriesId"
            :imageURL="imageURL"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="dataReport.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :nameChart="'Percentage'"
            :project ="project"
            :showListVideo="isShowListVideo"
            ></line-chart>
            <line-change-button
                v-else-if="!typeChart && !reactionButton.btn1.checked || !reactionButton.btn2.checked"
                @setChart="getChart"
                :part="part"
                :dataReport="dataReport"
                :step="step"
                :changeStepChart="changeStepOption"
                :seriesData="convertSeriesDataForEachInteraction"
                :selectedId="selectedSeriesId"
                :imageURL="imageURL"
                :column="column"
                :maxColumn="maxColumn"
                :lineChart="lineChart"
                :interactionType="dataReport.interaction_type"
                @addClassActive="addClassActive"
                @removeClassActive="removeClassActive"
                :nameChart="'Percentage'"
                :project ="project"
                :isArea="true"
                :checkedReactionButton="reactionButton"
                @setCurrentSelected="setCurrentSelected"
                :selectedSecond="currentSelected"
                :showListVideo="isShowListVideo"
            ></line-change-button>
            <area-range-line-chart
            @setChart="getChart"
            v-else
            :part="part"
            :dataReport="dataReport"
            :step="step"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :seriesData="getSeriesDataChartEachInteraction"
            :selectedId="selectedSeriesId"
            :interactionType="dataReport.interaction_type"
            :imageURL="imageURL"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :nameChart="'Percentage'"
            :project ="project"
            @setCurrentSelected="setCurrentSelected"
            :selectedSecond="currentSelected"
            :showListVideo="isShowListVideo"
          >
          </area-range-line-chart>
        </div>
        <div v-else>
          <line-chart
            v-if="typeChart"
            @setChart="getChart"
            :part="part"
            :dataReport="dataReport"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :selectedId="selectedSeriesId"
            :imageURL="imageURL"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="dataReport.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :nameChart="'Percentage'"
            :project ="project"
            :showListVideo="isShowListVideo"
          ></line-chart>
          <line-chart2
            v-else
            @setChart="getChart"
            :part="part"
            :dataReport="dataReport"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :selectedId="selectedSeriesId"
            :imageURL="imageURL"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="dataReport.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :nameChart="'Percentage'"
            :project ="project"
            :isArea="true"
            :showListVideo="isShowListVideo"
          ></line-chart2>
        </div>
       
        <div>
          <div
            class="btn btn-default"
            id="showListVideo"
            @click="showListVideo"
            data-compare-report="action"
            style="display: none"
          >
            リスト表示
          </div>
        </div>
      </div>
    </div>
    <setting-average 
      v-show="showSettingModal" 
      :buyerId="buyerId"
      :videos="videos"
      :checkedAvgIds = "checkedAvgIds"
      @closeModal="showSettingModal = false"
      @setSeriesData="setSeriesData"
      @checkedAvgIds = "getCheckedAvgIds"
    ></setting-average>
  </div>
</template>
<script>
import {colorInteraction, videoTimeRange} from "@/commons/constants"
import SettingAverage from "@/components/Top/SettingAverage"
import LineChart from "@/components/Charts/Line.vue";
import LineChangeButton from "@/components/Charts/Step2/LineChangeButton.vue";
import AreaRangeLine from "@/components/Charts/AreaRangeLine";
import StepOption from "@/components/Top/StepOption.vue";
import { stepOptionTopPage } from "@/commons/charts";
import EventBus from "@/commons/event_bus";
import { videosImages } from "@/commons/api_service";
import ButtonOption from "@/components/Top/ButtonOption.vue";

import {
  formatSeriesDataSummaryAvg,
  formatSeriesDataEachReactionAvg,
  formatSeriesDataSummaryVideo,
} from "@/commons/charts";
const jQuery = require("jquery");

let hiddenList = function () {
  jQuery("#listVideo").hide();
  jQuery("#showListVideo").show();
  jQuery(".video-report-area").height(400);
  jQuery("#chartArea").removeClass("row-3-col-2").addClass("row-3-col-3");
};
let showList = function () {
  jQuery("#listVideo").show();
  jQuery("#showListVideo").hide();
  jQuery(".video-report-area").height(353);
  jQuery("#chartArea").removeClass("row-3-col-3").addClass("row-3-col-2");
};

export default {
  components: { 
    LineChart,
    StepOption,
    AreaRangeLineChart: AreaRangeLine,
    SettingAverage ,
    'line-chart2' : LineChart,
    ButtonOption,
    LineChangeButton,
  },

  props: {
    videos: Array,
    dataReport: Object,
    adId: Number,
    buyerId: Number,
    summaryAvg: Object,
    checkedPrj : Boolean,
    project : Object,
  },
  data() {
    return {
      chart: null,
      checked: false,
      clients: [],
      stepOptions: stepOptionTopPage,
      changeStepOption: "changeStep",
      selectedSeriesId: "avg",
      shownReportIds: ["avg"],
      step: 1,
      part: 1,
      seriesData: [],
      imageURL: "",
      typeChart: true,
      column: 8,
      maxColumn: 8.5,
      lineChart: "chartVideo",
      videosMaxLengthSec: "",
      videosImages : [],
      showSettingModal : false,
      avgs : [{
        checked: true,
        id: "avg",
        name: "すべての動画",
        value: 0
      }
      ],
      checkedAvgIds: ["avg"],
      summaryAvgData : {},
      projectSettingSelected : [],
      videoTimeRangesUnCheck : [],
      reactionButton : {
        btn1: {
          text: null,
          color: null,
          checked: true,
        },
        btn2: {
          text: null,
          color: null,
          checked: true,
        }
      },
      currentSelected: 0,
      isShowListVideo: true
    };
  },
  watch: {
    dataReport: {
      handler: function () {
        EventBus.$emit('reloadSettingAverage');
        this.avgs = [];
        this.avgs.push(videoTimeRange[0]);
        this.part = 1;
      },
      deep: true,
    },
    buyerId: {
      handler: function() {
        EventBus.$emit('reloadSettingAverage')
        this.changeTypeChart(true)
      }
    },
    summaryAvg: {
      handler: function() {
        this.seriesData = [formatSeriesDataSummaryAvg(this.summaryAvg)];
        this.summaryAvgData = this.summaryAvg;
      }
    },
    videos: function(e) {
      
      this.videosMaxLength();
      this.defaultStepOption();
      let videoIds = e.map(item => item.id);
      if (videoIds.length > 0) {
          videosImages((data) => {
            this.videosImages = data;
            if (this.checkedPrj) {
              this.selectedSeriesId = "avg";
              this.shownReportIds = ["avg"];
              this.seriesData = [formatSeriesDataSummaryAvg(this.summaryAvg)]; 
              this.$store.commit('updateVideoList', this.adId)
              for(let video of this.videos) {
                  this.checkedVideo(video, true)
                }
            }
            this.$emit('loadVideosImages');
          }, {videoIds : videoIds + '', interactionTally : 1, adId : this.adId});
          
        } else {
          this.selectedSeriesId = "avg";
          this.shownReportIds = ["avg"];
          this.seriesData = [formatSeriesDataSummaryAvg(this.summaryAvg)]; 
          this.$emit('loadVideosImages');
        }
    },
    summaryAvgData :  {
      handler : function(data) {
        for (let time of videoTimeRange) {
          this.changeSeriesData(time.id, data);
        }
      }
    },
    project: function(value){
      if (value && value.interaction_btn_1 && Object.keys(value.interaction_btn_1).length) {
        this.reactionButton.btn1 = {
          ...this.reactionButton.btn1,
          ...{
            text: value.interaction_btn_1.text,
            color: colorInteraction[value.interaction_btn_1.color].color
          }
        }
      }

      if (value && value.interaction_btn_2 && Object.keys(value.interaction_btn_2).length) {
        this.reactionButton.btn2 = {
          ...this.reactionButton.btn2,
          ...{
            text: value.interaction_btn_2.text,
            color: colorInteraction[value.interaction_btn_2.color].color
          }
        }
      }
    }
  },
  methods: {
    changeSeriesData(id, data){
      let index = this.seriesData.findIndex(item => item.id == id);
      if (index != -1) {
        let serieData = formatSeriesDataSummaryAvg(data,id);
        this.seriesData.splice(index, 1, serieData);
      }
    },
    setSeriesData(data){
      let videoTimeRanges = data.videoTimeRange;
      let summaryAvg = data.summaryAvg;
      let projectIds = data.projectIds;
      this.avgs = [];
      this.summaryAvgData = summaryAvg;
      this.projectSettingSelected = projectIds;
      this.videoTimeRangesUnCheck = [];
      videoTimeRanges.forEach(item => {
        this.avgs.push(videoTimeRange.find(range => range.value == item.value))
      })
      videoTimeRange.forEach(item => {
        this.findVideoTimeRangeUnCheck(item)
      });
      this.videoTimeRangesUnCheck.forEach(item => {
        this.findSeriaData(item);
        this.findShowReportIds(item);
      })

      this.$emit("selected",this.summaryAvgData,this.avgs)
     
    },
    checkingVideoTimeRange(item){
      let result = this.shownReportIds.find(id => id == item.id);
      if (!result) {
        this.$set(item, "checked", false)
      } else {
        this.$set(item, "checked", true)
      }
    },
    findVideoTimeRangeUnCheck(time){
      let index = this.avgs.findIndex(item => item.id == time.id);
      if ( index == - 1) {
        this.videoTimeRangesUnCheck.push(time);
      }
    },
    findSeriaData(time){
      let index = this.seriesData.findIndex(item => item.id == time.id);
      if (index != -1) {
        this.seriesData.splice(index, 1)
      }
    },
    findShowReportIds(time) {
      let index = this.shownReportIds.findIndex(item => item == time.id);
      if (index != -1) {
        this.shownReportIds.splice(index, 1)
      }
    },
    selectedChart(id) {
      let video;
      if (id == "avg") {
        video = "avg";
      } else if (id == "avg-0-15") {
        video = "avg-0-15";
      } else if (id == "avg-16-30") {
        video = "avg-16-30";
      } else if (id == "avg-31-60") {
        video = "avg-31-60";
      } else if (id == "avg-61") {
        video = "avg-61";
      }  else {
        video = this.videos.find(e => e.id == id);
      }
      this.selectedVideo(video, false)
    },
    changeTypeChart(isTotalInteraction) {
      this.typeChart = isTotalInteraction;
      this.part = 1;
    },
    getChart(chart) {
      this.chart = chart;
    },
    hideListVideo() {
      this.isShowListVideo = false
      hiddenList();
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow();
        }
      });
    },
    showListVideo() {
      this.isShowListVideo = true
      showList();
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow();
        }
      });
    },
    selectedVideo(video, reload = true) {
      let selectedSeriesId;
      if (video == "avg" || video == "avg-0-15" || video == "avg-16-30" || video == "avg-31-60" || video == "avg-61") {
        selectedSeriesId = video;
      } 
      else {
        selectedSeriesId = "video-" + video.id;
      }

      if (this.shownReportIds.find((e) => e == selectedSeriesId)) {
        this.selectedSeriesId = selectedSeriesId;
        this.imageURL = video == "avg" ? "" : video.imageURL
        if (reload) {
          this.$nextTick(() => {
            this.part = 1;
          })
        }
      }
    },
    shownAvg(item) {
      let id = item.id;
      let index = this.shownReportIds.findIndex((e) => e == id);
      if (index == -1) {
        this.shownReportIds.push(id);
        let serieData = formatSeriesDataSummaryAvg(this.summaryAvgData,id);
        this.seriesData.push(serieData);
      } else {
        let index2 = this.seriesData.findIndex((e) => e.id == id);
        if (this.selectedSeriesId == id) {
          this.selectedSeriesId = null;
        }
        this.shownReportIds.splice(index, 1);
        this.seriesData.splice(index2, 1);
      }
    },
    getCheckedAvgIds(checkedAvgIds){
      this.checkedAvgIds = checkedAvgIds;
      this.$emit('checkedAvgs', this.checkedAvgIds);
    },
    getAvgId(item){ 
      let checkAvgId;
      if(this.shownReportIds.find(e => e == item.id) == item.id){
        checkAvgId = item.id;
        this.checkedAvgIds.push(checkAvgId);
      } else {
        let index = this.checkedAvgIds.indexOf(item.id)
        this.checkedAvgIds.splice(index,1);
      }
      this.$emit('checkedAvgs', this.checkedAvgIds);
    },
    checkedVideo(video, isInitData = false) {
      let index = this.shownReportIds.findIndex(
        (e) => e == "video-" + video.id
      );
      if (isInitData && this.$route.name === 'top-compare') {
        this.$set(video, "checked", true);
      } else {
        this.$set(video, "checked", !video.checked);
      }
      this.$emit("selectVideo", video);
      if (index == -1 && video.checked) {
        this.shownReportIds.push("video-" + video.id);
        let dataReport = this.$store.getters.getReportSummary;
        let videoReport =  dataReport ? dataReport.report_data.find(
          (e) => e.video_id == video.id
        ) : {};
        let serieData = formatSeriesDataSummaryVideo(videoReport, video);
        this.seriesData.push(serieData);
        if (!video.imageURL) {
          let videoImages = this.videosImages.find(item => item.video_id == video.id);
          video.imageURL = videoImages ? videoImages.image_url : '';
          if (videoImages) {
              this.$set(video, "image_url", videoImages.image_url);
              this.$set(video, "duration", videoImages.duration);
              this.$set(video, "2_rank", videoImages.interaction_rank["2_rank"]);
              this.$set(video, "3_rank", videoImages.interaction_rank["3_rank"]);
          }
          if (this.selectedSeriesId ==  "video-" + video.id) {
            this.imageURL = video.imageURL
          }
        }
      } else {
        let index2 = this.seriesData.findIndex(
          (e) => e.id == "video-" + video.id
        );
        if (this.selectedSeriesId == "video-" + video.id) {
          this.selectedSeriesId = null;
        }
        if (index2 != -1) {
          this.shownReportIds.splice(index, 1);
          this.seriesData.splice(index2, 1);
        }
      }
    },
    handleChangeStepChart(step) {
      this.step = step;
      this.part = 1;
    },
    nextPart() {
      this.part += 1;
    },
    prevPart() {
      this.part -= 1;
    },
    addClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
        jQuery("#" + data).addClass("active");
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
      }
    },
    resetCheckedVideo() {
      this.selectedSeriesId = "avg";
      this.shownReportIds = ["avg"];
      this.seriesData = [formatSeriesDataSummaryAvg(this.summaryAvg)];
      this.videos.forEach(video => {
        video.checked = false;
      })
      EventBus.$emit('resetVideos');
      EventBus.$emit('resetVideosMarket');
    },
    resetCheckedAvg(){
      let flg = false;
      for(let n=0; n<this.avgs.length; n++){
        if(this.avgs[n].id == 'avg'){
          flg = true;
          break;
        }
      }
      if(flg){
        this.checkedAvgIds.splice(0);
        this.checkedAvgIds.push('avg');
      } else {
        this.checkedAvgIds.splice(0);
      }
    },
    videosMaxLength() {
      let videoArrayNum = this.videos.length;
      let videoLengthArray = [];
      for (let i = 0; i < videoArrayNum; i++) {
        videoLengthArray.push(this.videos[i].video_length);
      }
      this.videosMaxLengthSec = videoLengthArray.reduce((a,b)=>Math.max(a,b), 0);
    },
    defaultStepOption() {
      let sec = this.videosMaxLengthSec;
      if ( 0 <= sec && sec <= 8 ) {
        this.step = 1;
      } else if ( 9 <= sec && sec <= 16 ) {
        this.step = 2;
      } else if ( 17 <= sec && sec <= 32 ) {
        this.step = 4;
      } else if ( 33 <= sec && sec <= 64 ) {
        this.step = 8;
      } else if ( 65 <= sec) {
        this.step = 15;
      }
    },
    handleChangeReactionButton(option) {
      if (this.reactionButton[option.type] != undefined) {
        this.reactionButton[option.type].checked = option.value
      }
    },
    setCurrentSelected(value) {
      this.currentSelected = value;
    },
    addCheckboxCustomData() {
      const svgElements = this.$el.querySelectorAll('.interaction-checkbox .icon-checkbox svg');

      if (svgElements) {
          svgElements.forEach(svgElement => {
            svgElement.setAttribute('data-cdai', 'action');
          });
      }
    }
  },
  computed: {
    nextPartEnabled: function () {
      let seriesLength = this.seriesData.map((e) => e.data.length);
      let max = Math.max(...seriesLength) - 1;
      return max > this.part * this.step * 8;
    },
    prevPartEnabled: function () {
      return this.part > 1;
    },
    interactionButtonColor: function(){
      let color_rank_2 = this.project && this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1).length ? this.project.interaction_btn_1.color : '';
      let color_rank_3 = this.project && this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2).length ? this.project.interaction_btn_2.color : '';
      return {
        rank_2 : color_rank_2 ,
        rank_3 : color_rank_3
      }
    },
    getSeriesDataChartEachInteraction: function () {
      let series = [];
      this.shownReportIds.forEach(id => {
        if (id == 'avg' || id == 'avg-0-15' || id == 'avg-16-30' || id == 'avg-31-60' || id == 'avg-61') {
          series.push(formatSeriesDataEachReactionAvg(this.summaryAvgData, id))
        } else {
          id = id.split('-')[1];
          let videoReportData = this.dataReport.report_data.find(e => e.video_id == id);
          if (videoReportData) {
            let serie = {
              id: 'video-' + id,
              data: videoReportData.tally_result.map(e => ([e.second, e.left, Math.abs(e.right)]))
            }
            series.push(serie)
          }
        }

      })
      return series;
    },
    convertSeriesDataForEachInteraction: function () {
      let seriesData = [];
      let vm = this;
      const series = JSON.parse(JSON.stringify(this.getSeriesDataChartEachInteraction))
      if (this.reactionButton.btn1.checked && !this.reactionButton.btn2.checked) {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function (valueArray) {
            return  valueArray[1]
          })
          item.colorLine = vm.interactionButtonColor.rank_2
          return item
        })
      } else if (!this.reactionButton.btn1.checked && this.reactionButton.btn2.checked) {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function (valueArray) {
            return valueArray[2]
          })
          item.colorLine = vm.interactionButtonColor.rank_3
          return item
        })
      } else {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function () {
            return null
          })
          return item
        })
      }

      return seriesData
    },
  },
  mounted() {
    this.videosMaxLength();
    this.defaultStepOption();
    this.addCheckboxCustomData();
  },
  created() {
    EventBus.$on("changeStep", this.handleChangeStepChart);
    EventBus.$on("resetCheckedVideo", this.resetCheckedVideo);
    EventBus.$on("resetCheckedAvg", this.resetCheckedAvg);
    EventBus.$on("selectedChart", this.selectedChart);
  },
  beforeDestroy() {
    EventBus.$off("changeStep", this.handleChangeStepChart);
    EventBus.$off("resetCheckedVideo", this.resetCheckedVideo);
  },
};
</script>
<style lang="scss" scoped>
</style>
