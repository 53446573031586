<template>
  <highcharts
      class="mt-2"
      :options="chartOptions"
      ref="StackColumnChart"
      :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import {Chart} from "highcharts-vue";
import EventBus from "@/commons/event_bus";
const jQuery = require("jquery");

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    part: Number,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    answerValueSelected: Array
  },
  watch: {
    step(newVal) {
      let vm = this
      this.minVal = 0
      this.maxVal = 0
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? (this.value + 1) * vm.step + (vm.part - 1) * 14 * vm.step : ''
        return this.value % 2 ? (this.value + 1) * newVal : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
      this.setYMax()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    answerValueIdSelected() {
      this.minVal = 0
      this.maxVal = 0
      this.formatDataChartWithStep()
    },
    selectedAnswerId() {
      this.formatDataChartWithStep()
    },
    answerValueSelected() {
      this.maxVal = 0
      this.setYMax()
    }
  },
  data() {
    let vm = this
    return {
      minSecond: 0,
      maxSecond: 13,
      maxVal: 0,
      minVal: 0,
      originSeries: {},
      answerMaxSecond: {},
      interactionBtnColor: this.$store.getters.interactioncolors,
      chartOptions: {
        chart: {
          height: "340px",
          type: 'column',
          animation: false
        },
        legend: {
          enabled: false
        },
        title: {
          text: ''
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 10,
            stacking: 'normal',
            borderColor: '#EBEBEB'
          },
          series: {
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  EventBus.$emit('selectedAnswerChart', e.target.point.series.userOptions.stack.split('-').pop())
                },
                mouseOver: function(e) {
                  vm.$nextTick(() => {
                    let pointId = e.target.originalEvent.path[0].point ? e.target.originalEvent.path[0].point.series.userOptions.stack : undefined
                    let elementHover = jQuery('.highcharts-point-hover')
                    let elementNegativeHover = jQuery('.highcharts-negative.highcharts-point-hover')
                    if (pointId) {
                      if (elementNegativeHover.length) {
                        elementNegativeHover.css({'fill': vm.interactionBtnColor[1], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                        elementNegativeHover.siblings().css({'fill': vm.interactionBtnColor[1], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                        let elementPoint = jQuery('.highcharts-point')
                        Object.values(elementPoint).map(el => {
                          if (el.point && el.point.series.userOptions.stack === pointId && el.point.series.userOptions.opacity === .1) {
                            jQuery(el).css({'fill': vm.interactionBtnColor[0], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                            jQuery(el).siblings().css({'fill': vm.interactionBtnColor[0], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                          }
                        })
                      } else {
                        elementHover.css({'fill': vm.interactionBtnColor[0], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                        elementHover.siblings().css({'fill': vm.interactionBtnColor[0], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                        let elementNegative = jQuery('.highcharts-negative')
                        Object.values(elementNegative).map(el => {
                          if (el.point && el.point.series.userOptions.stack === pointId) {
                            jQuery(el).css({'fill': vm.interactionBtnColor[1], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                            jQuery(el).siblings().css({'fill': vm.interactionBtnColor[1], 'stroke': '#EBEBEB', 'stroke-width': 1, 'opacity': 1})
                          }
                        })
                      }
                    } else {
                      if (elementNegativeHover.length) {
                        elementNegativeHover.css({'fill': vm.interactionBtnColor[1], 'opacity': 1})
                      } else {
                        elementHover.css({'fill': vm.interactionBtnColor[0], 'opacity': 1})
                      }
                    }
                  })
                },
                mouseOut: function() {
                  let elementPoint = jQuery('.highcharts-point')
                  elementPoint.removeAttr('style')
                  jQuery('.highcharts-negative').removeAttr('style')
                  Object.values(elementPoint).map(el => {
                    if (el.point && el.point.series.userOptions.stack !== "stack-" + vm.selectedAnswerId) {
                      jQuery(el).css({'stroke-width': 0})
                      jQuery(el).siblings().css({'stroke-width': 0})
                    } else {
                      jQuery(el).removeAttr('style')
                    }
                  })
                }
              }
            }
          }
        },
        xAxis: {
          min: -0.5,
          max: 13,
          tickWidth: 0,
          lineColor: '#EBEBEB',
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px'
            },
            formatter: function () {
              if (vm.part > 1) return this.value % 2 ? (this.value + 1) * vm.step + (vm.part - 1) * 14 * vm.step : ''
              return this.value % 2 ? (this.value + 1) * vm.step : ''
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 1,
          gridLineColor: '#EBEBEB',
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px'
            },
            formatter: function () {
              return this.value ? Math.abs(this.value) + '.0%' : this.value + '%'
            }
          }
        },
        series: []
      },
    };
  },
  methods: {
    initSeriesData() {
      if (this.reportScreening) {
        let seriesData = this.reportScreening.data
        if (seriesData && seriesData.length) {
          seriesData.map(data => {
            data.values.map(item => {
              this.answerMaxSecond[item.screening_answer_id] = 13
              if (data.second > this.answerMaxSecond[item.screening_answer_id]) {
                this.answerMaxSecond[item.screening_answer_id] = data.second
              }
              if (!this.originSeries[item.screening_answer_id]) {
                this.originSeries[item.screening_answer_id] = {}
              }
              this.originSeries[item.screening_answer_id][data.second] = {
                left_count: item.left_count,
                right_count: item.right_count
              }
            })
          })
          this.formatDataChartWithStep()
          this.setYMax()
        }
      }
    },
    formatDataChartWithStep() {
      this.maxSecond = 13
      let series = []
      if (this.answerValueIdSelected && this.answerValueIdSelected.length) {
        this.answerValueIdSelected.map(val => {
          if (this.answerMaxSecond[val] > this.maxSecond) this.maxSecond = this.answerMaxSecond[val]
          let valArrLeft = [], valArrRight = [], series_key = 0
          if (this.part > 1) series_key += (this.part - 1) * 14 * this.step
          for (let i = 1; i <= 14; i++) {
            if (i % 2 === 0) {
              valArrLeft.push(0)
              valArrRight.push(0)
            } else {
              let j = 0, totalLeft = 0, totalRight = 0
              while (j < this.step * 2) {
                if (this.originSeries[val] && this.originSeries[val][series_key] && series_key < this.step * this.part * 14) {
                  totalLeft += this.originSeries[val][series_key].left_count
                  totalRight += this.originSeries[val][series_key].right_count
                }
                series_key++
                j++
              }
              if (this.maxVal < totalLeft) this.maxVal = totalLeft
              if (Math.abs(this.minVal) < totalRight) this.minVal = -totalRight
              valArrLeft.push(totalLeft)
              valArrRight.push(-totalRight)
            }
          }
          series.push({
            color: this.interactionBtnColor[0],
            opacity: this.selectedAnswerId == val ? 1 : .1,
            data: valArrLeft,
            stack: "stack-" + val,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1
          })
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedAnswerId == val ? 1 : .07,
            data: valArrRight,
            stack: "stack-" + val,
            borderRadiusBottomRight: 8,
            borderRadiusBottomLeft: 8,
            borderWidth: 1
          })
        })
      } else {
        for (let i = 0; i < 8; i++) {
          let valArrLeft = [], valArrRight = []
          for (let i = 1; i <= 14; i++) {
            valArrLeft.push(0)
            valArrRight.push(0)
          }
          series.push({data: valArrLeft})
          series.push({data: valArrRight})
        }
      }
      if (this.answerValueIdSelected) {
        this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.answerValueIdSelected.length
      }
      this.chartOptions.series = series
      this.initJQuery()
    },
    setYMax() {
      if (this.answerValueSelected) {
        this.answerValueSelected.map(answer => {
          let answerKey = Object.keys(answer)[0]
          let series_key = 0
          if (this.part > 1) series_key += (this.part - 1) * 14 * this.step
          for (let i = 1; i <= 14; i++) {
            let j = 0, totalLeft = 0, totalRight = 0
            while (j < this.step * 2) {
              if (this.originSeries[answerKey] && this.originSeries[answerKey][series_key] && series_key < this.step * this.part * 14) {
                totalLeft += this.originSeries[answerKey][series_key].left_count
                totalRight += this.originSeries[answerKey][series_key].right_count
              }
              series_key++
              j++
            }
            if (this.maxVal < totalLeft) this.maxVal = totalLeft
            if (Math.abs(this.minVal) < totalRight) this.minVal = -totalRight
          }
        })
        if (this.minVal) {
          this.chartOptions.yAxis.min = this.minVal
        } else {
          this.chartOptions.yAxis.min = -20
        }
        if (this.maxVal) {
          this.chartOptions.yAxis.max = this.maxVal
        } else {
          this.chartOptions.yAxis.max = 40
        }
      }
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 14) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    },
    initJQuery() {
      this.$nextTick(() => {
        let elementPoint = jQuery('.highcharts-point')
        Object.values(elementPoint).map(el => {
          if (el.point && el.point.series.userOptions.stack !== "stack-" + this.selectedAnswerId) {
            jQuery(el).css({'stroke-width': 0})
            jQuery(el).siblings().css({'stroke-width': 0})
          } else {
            jQuery(el).removeAttr('style')
          }
        })
        let elementHover = jQuery('.highcharts-point-hover')
        let elementNegativeHover = jQuery('.highcharts-negative.highcharts-point-hover')
        if (elementNegativeHover.length) {
          elementNegativeHover.css({'fill': this.interactionBtnColor[1], 'opacity': 1})
        } else {
          elementHover.css({'fill': this.interactionBtnColor[0], 'opacity': 1})
        }
        jQuery('#chartArea .highcharts-point').attr('data-compare-report', 'action')
      })
    }
  },
  mounted() {
    this.initSeriesData()
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
::v-deep  {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }
}
</style>
