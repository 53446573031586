var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"video-capture"},[_c('div',{staticClass:"video-name"},[_c('a',{staticClass:"video-name link",attrs:{"data-cut-scene":"action"},on:{"click":function($event){return _vm.gotoDetailVideo(_vm.videoImage.id)}}},[_vm._v(" "+_vm._s(_vm.videoImage.name)+" "),_c('i',{staticClass:"icon icon-arrow-right"})])]),_c('div',{staticClass:"images mt-2"},_vm._l((_vm.listImage),function(image,index){return _c('div',{key:'second-' + index,staticClass:"image-second"},[(Object.keys(image).length)?_c('div',[_c('div',{staticClass:"tag-seconds"},[_vm._v(_vm._s(image.second)+"s")]),_c('div',{staticClass:"capture"},[_c('img',{staticClass:"capture-second",attrs:{"src":image.image_url}}),((index + _vm.step*9) !== _vm.videoImage.duration)?_c('i',{staticClass:"icon icon-arrow"}):_vm._e()]),(
                _vm.firstVideo.interaction_btn_1 &&
                Object.keys(_vm.firstVideo.interaction_btn_1).length &&
                Object.keys(image.rank_good).length
              )?_c('div',{class:'tag-number ' + _vm.classNameTag.rank_2},[_c('div',{staticClass:"ranking"},[_c('span',[_vm._v(_vm._s(image.rank_good.rank))])]),_c('div',{},[_vm._v(_vm._s(_vm._f("addPointZero")(_vm._f("numberWithCommas")(image.rank_good.interaction_count && image.rank_good.interaction_count % 1 != 0 ? image.rank_good.interaction_count.toFixed(1) : image.rank_good.interaction_count)))+"%")])]):_vm._e(),(
                _vm.firstVideo.interaction_btn_2 &&
                Object.keys(_vm.firstVideo.interaction_btn_2).length &&
                Object.keys(image.rank_bad).length
              )?_c('div',{class:'tag-number ' + _vm.classNameTag.rank_3},[_c('div',{staticClass:"ranking"},[_c('span',[_vm._v(_vm._s(image.rank_bad.rank))])]),_c('div',{},[_vm._v(_vm._s(_vm._f("addPointZero")(_vm._f("numberWithCommas")(image.rank_bad.interaction_count && image.rank_bad.interaction_count % 1 != 0 ? image.rank_bad.interaction_count.toFixed(1) : image.rank_bad.interaction_count)))+"%")])]):_vm._e()]):_vm._e()])}),0)]),(_vm.index !== _vm.checkedVideosLength - 1)?_c('div',{staticClass:"hr"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }