<template>
  <div id="distribution-map-container" class="distribution-map">
    <div id="video-type-container" class="flex justify-content-between align-items-center">
      <div id="video-type-box" class="flex align-items-center">
        <p class="title-section mt-0 mb-0">高反応・低反応分布図</p>
        <div id ="video-type-1" data-distribution-map="action" class="video-type ml-5 pr-2"
             :class="{'video-own-selected': videoOwnSelected, 'cursor-default': isLoadingMap}"
             @click="videoOwnSelected = !isLoadingMap ? true : videoOwnSelected">あなたの動画群
        </div>
        <div id ="video-type-2" class="video-type bl-1 pl-2" data-distribution-map="action"
             :class="{'video-own-selected': !videoOwnSelected, 'cursor-default': isLoadingMap}"
             @click="videoOwnSelected = !isLoadingMap ? false : videoOwnSelected, setXYVideoPoint()">
          PlayAdsの全動画群
        </div>
      </div>
      <div class="flex align-items-center">
        <div class="flex cursor-pointer pr-4" @click="mapHasTitle = !isLoadingMap ? true : mapHasTitle">
          <i class="icon icon-map-title" :class="{ checked: mapHasTitle}"></i>
          <div class="map-type pl-1" data-distribution-map="action" :class="{'map-has-title': mapHasTitle, 'cursor-default': isLoadingMap}">タイトルあり
          </div>
        </div>
        <div class="flex cursor-pointer bl-1 pl-4" @click="mapHasTitle = !isLoadingMap ? false : mapHasTitle">
          <i class="icon icon-map-no-title" :class="{ checked: !mapHasTitle}"></i>
          <div class="map-type pl-1" data-distribution-map="action" :class="{'map-has-title': !mapHasTitle, 'cursor-default': isLoadingMap}">タイトルなし
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center during-verification">
      <i class="icon icon-circle-green-small mr-1"></i>
      検証中
    </div>
    <div class="position-relative map mt-1 ml-5 mb-10">
      <div class="position-relative map-content" ref="map">
        <div class="position-relative bg light-green">
          <div v-if="firstVideo.interaction_btn_2.text=='Bad'" class="position-absolute btn-txt-y">{{ firstVideo.interaction_btn_2.text }}
            {{ !switchY ? '高反応' : '低反応' }}
          </div>
          <div v-else class="position-absolute btn-txt-y another-btn">反応値Y
            {{ !switchY ? '高反応' : '低反応' }}
          </div>
        </div>
        <div class="bg light-blue"></div>
        <div class="position-relative bg light-red">
          <div v-if="firstVideo.interaction_btn_2.text=='Bad'" class="position-absolute btn-txt-y">{{ firstVideo.interaction_btn_2.text }}
            {{ switchY ? '高反応' : '低反応' }}
          </div>
          <div v-else class="position-absolute btn-txt-y another-btn">反応値Y
            {{ switchY ? '高反応' : '低反応' }}
          </div>
          <div v-if="firstVideo.interaction_btn_1.text=='Good'" class="position-absolute btn-txt-x">{{ firstVideo.interaction_btn_1.text }}
            {{ switchX ? '高反応' : '低反応' }}
          </div>
          <div v-else class="position-absolute btn-txt-x">反応値X
            {{ switchX ? '高反応' : '低反応' }}
          </div>
        </div>
        <div class="position-relative bg light-green">
          <div v-if="firstVideo.interaction_btn_1.text=='Good'" class="position-absolute btn-txt-x">{{ firstVideo.interaction_btn_1.text }}
            {{ !switchX ? '高反応' : '低反応' }}
          </div>
          <div v-else class="position-absolute btn-txt-x">反応値X
            {{ !switchX ? '高反応' : '低反応' }}
          </div>
        </div>
        <svg>
          <line stroke-dasharray="5, 5" x1="0" :y1="lowLinePoint" x2="100%" :y2="highLinePoint"
                style="stroke:rgb(73,171,148);stroke-width:1"/>
        </svg>
        <div :class="avgTxtClass">平均反応率 <b>{{
            interactionAverageReport.total_average !== undefined ? interactionAverageReport.total_average.toFixed(1) + '%' : ''
          }}</b></div>
        <div id="icon-switch-y-box" class="flex position-absolute switch-y" @click="switchYActive(), reversalLine()">
          <i id = "icon-switch-y" class="icon icon-switch icon-switch-y" data-distribution-map="action"></i>
        </div>
        <div id="icon-switch-x-box" class="flex position-absolute switch-x" @click="switchXActive()">
          <i id="icon-switch-x" class="icon icon-switch" data-distribution-map="action"></i>
        </div>
        <div v-if="isLoadingMap" class="flex position-absolute icon-loading-map">
          <div class="loader"></div>
        </div>
          <div v-for="(videoPoint, index) in initialInteractionAverageReport.video_average" :key="videoPoint.id"
              v-show="filterVideo(videoPoint.video_id)"
              class="d-flex align-items-center map-video-point position-absolute"
              :class="{'map-video-point-title': mapHasTitle, 'open-video-label': videoPoint.dupulicate_flg , 'delete-shadow': videoPoint.dupulicate_flg && !videoPoint.top_video, 'add-shadow': videoPoint.open_flg, 'top-video-label': videoPoint.top_video, 'bottom-video-label': videoPoint.bottom_video && !videoPoint.open_flg}" :style="mapVideoPoints[index] "
              :ref="'map-video-point-' + videoPoint.video_id"
              @click="openVideo(videoPoint)">
              <div v-for="(dupulicationVideoCnt, idx) in duplicationVideoCounts" :key="idx">
                <div v-if="dupulicationVideoCnt.video_id == videoPoint.video_id && !videoPoint.open_flg" class="video-count position-absolute">
                  <p>{{ dupulicationVideoCnt.count }}</p>
                </div>
              </div>
            <template v-if="mapHasTitle">
              <div class="map-video-img"
                  :style="{ backgroundImage: `url(${filterVideo(videoPoint.video_id) ? filterVideo(videoPoint.video_id).image_url + '2.jpg' : undefined})` }"></div>
              <div class="map-video-info ml-1">
                {{
                  formatMapVideoName(`${filterVideo(videoPoint.video_id) ? filterVideo(videoPoint.video_id).name : ''}`)
                }}<br/>
                <b>{{ videoPoint.total.toFixed(1) }}%</b>
              </div>
              <div v-if="filterVideoDetail(videoPoint.video_id) && filterVideoDetail(videoPoint.video_id).is_csv_downloadable === 0" class="position-absolute during-verification-tag"></div>
            </template>
            <template v-else>
              <div class="map-video-img cursor-pointer"
                  @mouseenter="enterHover(videoPoint.video_id)"
                  @mouseleave="leaveHover(videoPoint.video_id)"
                  :style="{ backgroundImage: `url(${filterVideo(videoPoint.video_id) ? filterVideo(videoPoint.video_id).image_url + '2.jpg' : undefined})` }"></div>
              <div v-if="mapVideoPointsHover[videoPoint.video_id]" class="position-absolute map-video-info-no-title"
                  :class="{'map-video-at-min-left': videoPoint.left === xMin && filterVideo(videoPoint.video_id).name.length > 7 && !switchX || videoPoint.left === xMax && filterVideo(videoPoint.video_id).name.length > 7 && switchX}">
                {{
                  formatMapVideoName(`${filterVideo(videoPoint.video_id) ? filterVideo(videoPoint.video_id).name : ''}`)
                }}：{{ videoPoint.total.toFixed(1) }}%
              </div>
              <div v-if="mapVideoPointsHover[videoPoint.video_id]" class="position-absolute flex icon-arrow-bottom">
                <i class="icon icon-map-video-arrow-bottom"></i>
              </div>
              <div v-if="filterVideoDetail(videoPoint.video_id).is_csv_downloadable === 0" class="position-absolute during-verification-tag"></div>
            </template>
          </div>
      </div>
      <div v-if="firstVideo.interaction_btn_2.text=='Bad'" class="position-absolute btn-1-txt">{{ firstVideo.interaction_btn_2.text }}</div>
      <div v-else class="position-absolute btn-1-txt">反応値Y</div>
      <div v-if="firstVideo.interaction_btn_1.text=='Good'" class="position-absolute btn-2-txt">{{ firstVideo.interaction_btn_1.text }}</div>
      <div v-else class="position-absolute btn-2-txt">反応値X</div>
      <div class="position-absolute blank-block"></div>
      <div class="position-absolute flex triangle-x">
        <i class="icon icon-triangle"></i>
      </div>
      <div class="position-absolute flex triangle-y">
        <i class="icon icon-triangle"></i>
      </div>
    </div>
  </div>
</template>
<script>
const jQuery = require("jquery");
import { distributionMapPosition } from "../../commons/constants";

export default {
  name: 'DistributionMap',
  props: ['firstVideo', 'checkedVideos', 'initialInteractionAverageReport', 'interactionAverageReport', 'isLoadingMap', 'videos'],
  data() {
    return {
      videoOwnSelected: true,
      mapHasTitle: false,
      switchX: false,
      switchY: false,
      mapVideoPoints: [],
      mapVideoPointsHover: {},
      xMin: 0,
      xMax: 1,
      yMin: 0,
      yMax: 1,
      mapWidth: 0,
      mapHeight: 0,
      highLinePoint: '100%',
      lowLinePoint: '0',
      avgTxtClass: 'avg-txt',
      videoPointCheck: [{
        y_value: null,
        x_value: null,
        video_id: null
      }],
      duplicationVideoId: [],
      duplicationVideoCounts: [],
      topVideoIds: [],
      bottomVideoIds: [],
      clickedVideoIds: [],
      swichCheck: false,
      mapPosition: distributionMapPosition,
    }
  },
  watch: {
    videoOwnSelected(val) {
      if (!this.isLoadingMap) {
        let type = val ? 0 : 1
        this.$emit('changeInteractionAverageType', type)
      }
    },
    initialInteractionAverageReport(val) {
      if (Object.keys(val).length) {
        val.video_average.map((videoPoint) => {
          this.mapVideoPoints.push({
            transform: `translateY(-17px)translateX(-18px)`
          })
          this.mapVideoPointsHover[videoPoint.video_id] = false
        })
        this.xMin = val.left_line.min
        this.xMax = val.left_line.max
        this.yMin = val.right_line.min
        this.yMax = val.right_line.max
        this.setXYVideoPoint()
      }
    },
    checkedVideos() {
      if (Object.keys(this.interactionAverageReport).length) {
        this.setXYVideoPoint()
      }
    },
    mapHasTitle(val){
      this.videoShapeChange(val)
    }
  },
  methods: {
    formatMapVideoName(name) {
      if (name.length > 11) {
        return name.slice(0, 11) + '...'
      }
      return name
    },
    filterVideoDetail(id) {
      return this.videos.filter(video => {
        return video.id == id
      })[0]
    },
    filterVideo(id) {
      return this.checkedVideos.filter(video => {
        return video.id == id
      })[0]
    },
    setXYVideoPoint() {
      let initialYPoint = 0;
      let initialXPoint = 0;
      this.videoPointCheck = [{
        y_value: null,
        x_value: null,
        video_id: null
      }];

      if(this.initialInteractionAverageReport.video_average) {
        this.initialInteractionAverageReport.video_average.map((videoPoint, i) => {
          if (this.xMax === 0 && this.xMin === 0 && this.yMax === 0 && this.yMin === 0) {
            this.mapVideoPoints[i].transform = null
          } else {
            if (this.switchX && this.switchY) {
              initialYPoint = (videoPoint.right - this.yMin) / (this.yMax - this.yMin) * this.mapHeight - 17;
              initialXPoint = (100 - Math.round((videoPoint.left - this.xMin) / (this.xMax - this.xMin) * 100)) / 100 * this.mapWidth - 18;
              if(initialYPoint <= -17){
                this.mapVideoPoints[i].transform = `translateY(-17px)`
                this.videoPointCheck[i].y_value = 2;
              } else if(initialYPoint >= this.mapHeight || videoPoint.right == 0){
                this.mapVideoPoints[i].transform = `translateY(${this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 1;
              } else {
                this.mapVideoPoints[i].transform = `translateY(${(videoPoint.right - this.yMin) / (this.yMax - this.yMin) * this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 3;
              }
              if(initialXPoint <= -18){
                this.mapVideoPoints[i].transform += `translateX(-18px)`
                this.videoPointCheck[i].x_value = 1;
              } else if(initialXPoint >= this.mapWidth || videoPoint.left == 0){
                this.mapVideoPoints[i].transform += `translateX(${this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 2;
              } else {
                this.mapVideoPoints[i].transform += `translateX(${(100 - Math.round((videoPoint.left - this.xMin) / (this.xMax - this.xMin) * 100)) / 100 * this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 3;
              }
            } else if (this.switchX) {
              initialYPoint = (100 - Math.round((videoPoint.right - this.yMin) / (this.yMax - this.yMin) * 100)) / 100 * this.mapHeight - 17;
              initialXPoint = (100 - Math.round((videoPoint.left - this.xMin) / (this.xMax - this.xMin) * 100)) / 100 * this.mapWidth - 18;
              if(initialYPoint <= -17){
                this.mapVideoPoints[i].transform = `translateY(-17px)`
                this.videoPointCheck[i].y_value = 2;
              } else if(initialYPoint >= this.mapHeight || videoPoint.right == 0){
                this.mapVideoPoints[i].transform = `translateY(${this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 1;
              } else {
                this.mapVideoPoints[i].transform = `translateY(${(100 - Math.round((videoPoint.right - this.yMin) / (this.yMax - this.yMin) * 100)) / 100 * this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 3;
              }
              if(initialXPoint <= -18){
                this.mapVideoPoints[i].transform += `translateX(-18px)`
                this.videoPointCheck[i].x_value = 1;
              } else if(initialXPoint >= this.mapWidth || videoPoint.left == 0){
                this.mapVideoPoints[i].transform += `translateX(${this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 2;
              } else {
                this.mapVideoPoints[i].transform += `translateX(${(100 - Math.round((videoPoint.left - this.xMin) / (this.xMax - this.xMin) * 100)) / 100 * this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 3;
              }
            } else if (this.switchY) {
              initialYPoint = (videoPoint.right - this.yMin) / (this.yMax - this.yMin) * this.mapHeight - 17;
              initialXPoint = (videoPoint.left - this.xMin) / (this.xMax - this.xMin) * this.mapWidth - 18;
              if(initialYPoint <= -17 || videoPoint.right == 0){
                this.mapVideoPoints[i].transform = `translateY(-17px)`
                this.videoPointCheck[i].y_value = 2;
              } else if(initialYPoint >= this.mapHeight){
                this.mapVideoPoints[i].transform = `translateY(${this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 1;
              } else {
                this.mapVideoPoints[i].transform = `translateY(${(videoPoint.right - this.yMin) / (this.yMax - this.yMin) * this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 3;
              }
              if(initialXPoint <= -18  || videoPoint.left == 0){
                this.mapVideoPoints[i].transform += `translateX(-18px)`
                this.videoPointCheck[i].x_value = 1;
              } else if(initialXPoint >= this.mapWidth){
                this.mapVideoPoints[i].transform += `translateX(${this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 2;
              } else {
                this.mapVideoPoints[i].transform += `translateX(${(videoPoint.left - this.xMin) / (this.xMax - this.xMin) * this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 3;
              }
            } else {
              initialYPoint = 100 - Math.round((videoPoint.right - this.yMin) / (this.yMax - this.yMin) * 100) - 17;
              initialXPoint = (videoPoint.left - this.xMin) / (this.xMax - this.xMin) * this.mapWidth - 18;
              if(initialYPoint <= -17){
                this.mapVideoPoints[i].transform = `translateY(-17px)`
                this.videoPointCheck[i].y_value = 2;
              } else if(initialYPoint >= this.mapHeight || videoPoint.right == 0){
                this.mapVideoPoints[i].transform = `translateY(${this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 1;
              } else {
                this.mapVideoPoints[i].transform = `translateY(${(100 - Math.round((videoPoint.right - this.yMin) / (this.yMax - this.yMin) * 100)) / 100 * this.mapHeight - 17}px)`
                this.videoPointCheck[i].y_value = 3;
              }
              if(initialXPoint <= -18 || videoPoint.left == 0){
                this.mapVideoPoints[i].transform += `translateX(-18px)`
                this.videoPointCheck[i].x_value = 1;
              } else if(initialXPoint >= this.mapWidth){
                this.mapVideoPoints[i].transform += `translateX(${this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 2;
              } else {
                this.mapVideoPoints[i].transform += `translateX(${(videoPoint.left - this.xMin) / (this.xMax - this.xMin) * this.mapWidth - 18}px)`
                this.videoPointCheck[i].x_value = 3;
              }
            }
            this.mapVideoPoints[i].video_id = videoPoint.video_id;
            this.videoPointCheck[i].video_id = videoPoint.video_id;
            this.videoPointCheck.push({
              y_value: null,
              x_value: null,
              video_id: null
            });
          }
        })
        this.setDupulicateValue();
      }
      this.checkDupulicateVideo();
      this.countDupulicateVideo();
    },
    checkDupulicateVideo(){
      this.videoPointCheck.pop();
      let compareArray = this.videoPointCheck;
      this.duplicationVideoId = [];
      for(let video_i = 0; video_i < this.videoPointCheck.length; video_i++){
        let value = 0;
        let duplicateFlg = false;
        if(this.videoPointCheck[video_i].x_value == 1 && this.videoPointCheck[video_i].y_value == 1){
          value = this.mapPosition.bottom_left;
        } else if(this.videoPointCheck[video_i].x_value == 1 && this.videoPointCheck[video_i].y_value == 2){
          value = this.mapPosition.upper_left;
        } else if(this.videoPointCheck[video_i].x_value == 2 && this.videoPointCheck[video_i].y_value == 1){
          value = this.mapPosition.bottom_right;
        } else if(this.videoPointCheck[video_i].x_value == 2 && this.videoPointCheck[video_i].y_value == 2) {
          value = this.mapPosition.upper_right;
        }
        if(value != 0){
          for(let com_j = 0; com_j < compareArray.length; com_j++){
            if(this.videoPointCheck[video_i].video_id !== compareArray[com_j].video_id && this.videoPointCheck[video_i].x_value == compareArray[com_j].x_value && this.videoPointCheck[video_i].y_value == compareArray[com_j].y_value){
              this.duplicationVideoId.push({
                  value: value,
                  video_id: compareArray[com_j].video_id,
              })
              duplicateFlg = true;
            }
          }
          if(duplicateFlg){
              this.duplicationVideoId.push({
                  value: value,
                  video_id: this.videoPointCheck[video_i].video_id,
                  open_flg: false,
              })
              duplicateFlg = false;
          }
        }
      }
      this.duplicationVideoId = Array.from(new Map(this.duplicationVideoId.map((val) => [val.video_id, val])).values());
    },
    countDupulicateVideo(){
      this.topVideoIds = [];
      this.bottomVideoIds = [];
      let bottomRightCnt = 0;
      let upperLeftCnt = 0;
      let bottomLeftCnt = 0;
      let upperRightCnt = 0;
      let bottomRightVideoId = null;
      let bottomRightBottomVideoId = null;
      let upperLeftVideoId = null;
      let upperLeftBottomVideoId = null;
      let bottomLeftVideoId = null;
      let bottomLeftBottomVideoId = null;
      let upperRightVideoId = null;
      let upperRightBottomVideoId = null;
      this.duplicationVideoCounts = [];
      if(this.duplicationVideoId){
        for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
          if(this.duplicationVideoId[dup_i].value == this.mapPosition.bottom_left){
            if(bottomLeftCnt == 0) bottomLeftBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            bottomLeftCnt++;
            if(this.duplicationVideoId[dup_i].video_id > bottomLeftVideoId){
              bottomLeftVideoId = this.duplicationVideoId[dup_i].video_id;
            } else if(this.duplicationVideoId[dup_i].video_id < bottomLeftBottomVideoId) {
              bottomLeftBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            }
          } else if(this.duplicationVideoId[dup_i].value == this.mapPosition.upper_left){
            if(upperLeftCnt == 0) upperLeftBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            upperLeftCnt++;
            if(this.duplicationVideoId[dup_i].video_id > upperLeftVideoId){
              upperLeftVideoId = this.duplicationVideoId[dup_i].video_id;
            } else if(this.duplicationVideoId[dup_i].video_id < upperLeftBottomVideoId){
              upperLeftBottomVideoId = this.duplicationVideoId[dup_i].video_id
            }
          } else if(this.duplicationVideoId[dup_i].value == this.mapPosition.bottom_right){
            if(bottomRightCnt == 0)bottomRightBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            bottomRightCnt++;
            if(this.duplicationVideoId[dup_i].video_id > bottomRightVideoId){
              bottomRightVideoId = this.duplicationVideoId[dup_i].video_id;
            } else if(this.duplicationVideoId[dup_i].video_id < bottomRightBottomVideoId){
              bottomRightBottomVideoId = this.duplicationVideoId[dup_i].video_id
            }
          } else{
            if(upperRightCnt == 0) upperRightBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            upperRightCnt++;
            if(this.duplicationVideoId[dup_i].video_id > upperRightVideoId){
              upperRightVideoId = this.duplicationVideoId[dup_i].video_id;
            } else if(this.duplicationVideoId[dup_i].video_id < upperRightBottomVideoId){
              upperRightBottomVideoId = this.duplicationVideoId[dup_i].video_id;
            }
          }
        }
        if(bottomLeftCnt !== 0){
          this.topVideoIds.push(bottomLeftVideoId);
          this.bottomVideoIds.push(bottomLeftBottomVideoId);
          this.duplicationVideoCounts.push({
            count: bottomLeftCnt,
            video_id: bottomLeftVideoId
          })
        }
        if(upperLeftCnt !== 0){
          this.topVideoIds.push(upperLeftVideoId);
          this.bottomVideoIds.push(upperLeftBottomVideoId);
          this.duplicationVideoCounts.push({
            count: upperLeftCnt,
            video_id: upperLeftVideoId
          })
        }
        if(bottomRightCnt !== 0){
          this.topVideoIds.push(bottomRightVideoId);
          this.bottomVideoIds.push(bottomRightBottomVideoId);
          this.duplicationVideoCounts.push({
            count: bottomRightCnt,
            video_id: bottomRightVideoId
          })
        }
        if(upperRightCnt !== 0){
          this.topVideoIds.push(upperRightVideoId);
          this.bottomVideoIds.push(upperRightBottomVideoId);
          this.duplicationVideoCounts.push({
            count: upperRightCnt,
            video_id: upperRightVideoId
          })
        }
        this.topVideoIds = this.topVideoIds.filter((element, index) => this.topVideoIds.indexOf(element) === index);
        this.bottomVideoIds = this.bottomVideoIds.filter((element, index) => this.bottomVideoIds.indexOf(element) === index);
      }
    },
    setDupulicateValue(){
      for(let init_i = 0; init_i < this.initialInteractionAverageReport?.video_average.length; init_i++){
        for(let dup_j = 0; dup_j < this.duplicationVideoId.length; dup_j++){
          if(this.initialInteractionAverageReport?.video_average[init_i].video_id == this.duplicationVideoId[dup_j].video_id){
            this.initialInteractionAverageReport.video_average[init_i].dupulicate_flg = true;
            break;
          }
        }
      }
      for(let init_i = 0; init_i < this.initialInteractionAverageReport?.video_average.length; init_i++){
        this.initialInteractionAverageReport.video_average[init_i].top_video =false;
        for(let dup_j = 0; dup_j < this.topVideoIds.length; dup_j++){
          if(this.initialInteractionAverageReport?.video_average[init_i].video_id == this.topVideoIds[dup_j]){
            this.initialInteractionAverageReport.video_average[init_i].top_video = true;
          } else if(!this.initialInteractionAverageReport?.video_average[init_i].top_video){
            this.initialInteractionAverageReport.video_average[init_i].top_video = false;
          }
        }
      }
      for(let init_i = 0; init_i < this.initialInteractionAverageReport?.video_average.length; init_i++){
        this.initialInteractionAverageReport.video_average[init_i].bottom_video = false;
        for(let dup_j = 0; dup_j < this.bottomVideoIds.length; dup_j++){
          if(this.initialInteractionAverageReport?.video_average[init_i].video_id == this.bottomVideoIds[dup_j]){
            this.initialInteractionAverageReport.video_average[init_i].bottom_video = true;
          } else if(!this.initialInteractionAverageReport?.video_average[dup_j].bottom_video){
            this.initialInteractionAverageReport.video_average[dup_j].bottom_video = false;
          }
        }
      }
    },
    switchXActive() {
      this.switchX = !this.switchX
      if (Object.keys(this.initialInteractionAverageReport).length) {
        this.setXYVideoPoint()
      }
      this.switchRemoveShadow();
      this.setOpenFlg();
    },
    switchYActive() {
      this.switchY = !this.switchY
      if (Object.keys(this.initialInteractionAverageReport).length) {
        this.setXYVideoPoint()
      }
      this.switchRemoveShadow();
      this.setOpenFlg();
    },
    switchRemoveShadow(){
      let id = 0;
      for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
        id = this.duplicationVideoId[dup_i].video_id
        jQuery(this.$refs['map-video-point-' + id][0]).removeClass('add-shadow')
        for(let bot_j = 0; bot_j < this.bottomVideoIds.length; bot_j++){
          if(this.bottomVideoIds[bot_j] == id){
            jQuery(this.$refs['map-video-point-' + id][0]).addClass('bottom-video-label')
          }
        }
      }
      this.swichCheck = true;
    },
    reversalLine(){
      if(this.highLinePoint == '100%'){
        this.highLinePoint = '0';
        this.lowLinePoint = '100%';
        this.avgTxtClass = 'avg-right-txt';
      } else {
        this.highLinePoint = '100%';
        this.lowLinePoint = '0';
        this.avgTxtClass = 'avg-txt';
      }
    },
    enterHover(id) {
      this.mapVideoPointsHover[id] = true
      jQuery(this.$refs['map-video-point-' + id][0]).addClass('map-video-point-hover')
      this.mapVideoPointsHover = {...this.mapVideoPointsHover}
    },
    leaveHover(id) {
      this.mapVideoPointsHover[id] = false
      jQuery(this.$refs['map-video-point-' + id][0]).removeClass('map-video-point-hover')
      this.mapVideoPointsHover = {...this.mapVideoPointsHover}
    },
    openVideo(val){
      for(let top_i = 0; top_i < this.topVideoIds.length; top_i++){
        if(this.topVideoIds[top_i] == val.video_id){
          let initTranslateXText = '';
          let translateXText = '';
          let initTranslateYText = '';
          let translateYText = '';
          let translateX = '';
          let translateY = '';
          let addXNum = 0;
          let addYNum = 45;
          let breakCnt = 1;
          let clickVal = 0;
          let firstBreak = false;
          let breakFlg = false;
          let videoClickFlg = false;
          if(this.swichCheck){
            videoClickFlg = false;
            if(this.clickedVideoIds !== []) this.clickedVideoIds = []
          }
          for(let click_j = 0; click_j < this.clickedVideoIds.length; click_j++){
            if(this.clickedVideoIds[click_j] == val.video_id){
              videoClickFlg = true;
            }
          }
          if(this.mapHasTitle){
            addXNum = 160;
          } else{
            addXNum = 40;
          }
          for(let dup_j = 0; dup_j < this.duplicationVideoId.length; dup_j++){
            if(val.video_id == this.duplicationVideoId[dup_j].video_id){
              clickVal = this.duplicationVideoId[dup_j].value;
            }
          }
          if(!videoClickFlg){
            for(let dup_j = 0; dup_j < this.duplicationVideoId.length; dup_j++){
              for(let map_k = 0; map_k < this.mapVideoPoints.length; map_k++){
                if(this.duplicationVideoId[dup_j].video_id == this.mapVideoPoints[map_k].video_id && this.mapVideoPoints[map_k].video_id !== val.video_id && this.duplicationVideoId[dup_j].value == clickVal){
                  if(breakCnt == 1) {
                    translateXText = this.mapVideoPoints[map_k].transform.match(/translateX.*\)/g)[0];
                    initTranslateYText = this.mapVideoPoints[map_k].transform.replace(/translateX.*\)/g, '');
                  }
                  if(breakFlg){
                    translateX = translateXText.match(/[+-]?\d+(?:\.\d+)?/g);
                    translateX= Number(translateX);
                    translateX = translateX + addXNum
                    translateXText = `translateX(${translateX}px)`;
                  }
                  if(breakFlg){
                    translateYText = initTranslateYText;
                    addYNum = 0;
                  } else {
                    translateYText = this.mapVideoPoints[map_k].transform.replace(/translateX.*\)/g, '');
                  }
                  translateY = translateYText.match(/[+-]?\d+(?:\.\d+)?/g);
                  translateY = Number(translateY);
                  translateY = translateY + addYNum
                  this.mapVideoPoints[map_k].transform = `translateY(${translateY}px)`;
                  this.mapVideoPoints[map_k].transform +=`${translateXText}`
                  this.duplicationVideoId[dup_j].open_flg = true;
                  addYNum += 45;
                  breakCnt++;
                  breakFlg = false;
                  if(breakCnt == 5){
                    firstBreak = true;
                    breakFlg = true;
                  } else if(firstBreak && breakCnt % 5 == 0){
                    breakFlg = true;
                  }
                }
                if(val.video_id == this.duplicationVideoId[dup_j].video_id && !this.duplicationVideoId[dup_j].open_flg){
                  this.duplicationVideoId[dup_j].open_flg = true;
                }
              }
            }
            let clickedFlg = false;
            if(this.clickedVideoIds){
              for(let click_j = 0; click_j < this.clickedVideoIds.length; click_j++){
                if(this.clickedVideoIds[click_j] == val.video_id){
                  clickedFlg = true;
                  break;
                }
              }
            }
            if(!clickedFlg){
              this.clickedVideoIds.push(val.video_id);
              this.clickedVideoIds = this.clickedVideoIds.filter((element, index) => this.clickedVideoIds.indexOf(element) === index);
            } 
            this.swichCheck = false;
          } else {
            let targetYText = null;
            for(let dup_j = 0; dup_j < this.mapVideoPoints.length; dup_j++){
              if(this.mapVideoPoints[dup_j].video_id == val.video_id){
                targetYText = this.mapVideoPoints[dup_j].transform.replace(/translateX.*\)/g, '');
              }
            }
            for(let dup_j = 0; dup_j < this.duplicationVideoId.length; dup_j++){
              for(let map_k = 0; map_k < this.mapVideoPoints.length; map_k++){
                if(this.duplicationVideoId[dup_j].video_id == this.mapVideoPoints[map_k].video_id && this.mapVideoPoints[map_k].video_id !== val.video_id && this.duplicationVideoId[dup_j].value == clickVal){
                  if(breakCnt == 1){
                    initTranslateXText = this.mapVideoPoints[map_k].transform.match(/translateX.*\)/g);
                    initTranslateYText = targetYText;
                  }
                  this.mapVideoPoints[map_k].transform = initTranslateYText;
                  this.mapVideoPoints[map_k].transform += initTranslateXText[0]
                  this.duplicationVideoId[dup_j].open_flg = false;
                  breakCnt++;
                }
                if(val.video_id == this.duplicationVideoId[dup_j].video_id && this.duplicationVideoId[dup_j].open_flg){
                  this.duplicationVideoId[dup_j].open_flg = false;
                }
              }
            }
            let clickedFlg = false;
            let index = 0;
            if(this.clickedVideoIds){
              for(let click_j = 0; click_j < this.clickedVideoIds.length; click_j++){
                if(this.clickedVideoIds[click_j] == val.video_id){
                  clickedFlg = true;
                  index = click_j;
                  break;
                }
              }
            }
            if(clickedFlg) this.clickedVideoIds.splice(index, 1);
          }
          break;
        }
      }
      this.setOpenFlg();
    },
    setOpenFlg(){
      for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
        for(let init_j = 0; init_j < this.initialInteractionAverageReport?.video_average.length; init_j++){
          if(this.duplicationVideoId[dup_i].video_id == this.initialInteractionAverageReport?.video_average[init_j].video_id){
            if(this.duplicationVideoId[dup_i].open_flg){
              this.initialInteractionAverageReport.video_average[init_j].open_flg = this.duplicationVideoId[dup_i].open_flg
            } else if(!this.duplicationVideoId[dup_i].open_flg){
              this.initialInteractionAverageReport.video_average[init_j].open_flg = this.duplicationVideoId[dup_i].open_flg
            }
          }
        }
      }
    },
    videoShapeChange(val){
      let translateXText = '';
      let translateYText = '';
      let translateX = '';
      let multiplier = 0;
      let addXNum = 0;
      let openTopVideoIds = [];
      let openTopVideoTranslateXText = '';
      let openTopVideoTranslateX = 0;
      let openTopVideoTranslatesX = [];
      if(!val){
        for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
          for(let top_j = 0; top_j < this.topVideoIds.length; top_j++){
            if(this.duplicationVideoId[dup_i].open_flg && this.duplicationVideoId[dup_i].video_id == this.topVideoIds[top_j]){
              openTopVideoIds.push(this.topVideoIds[top_j]);
            }
          }
        }

       for(let map_i = 0; map_i < this.mapVideoPoints.length; map_i++){
        for(let open_j = 0; open_j < openTopVideoIds.length; open_j++){
          if(this.mapVideoPoints[map_i].video_id == openTopVideoIds[open_j]){
            openTopVideoTranslateXText = this.mapVideoPoints[map_i].transform.match(/translateX.*\)/g)[0];
            openTopVideoTranslateX = openTopVideoTranslateXText.match(/[+-]?\d+(?:\.\d+)?/g);
            openTopVideoTranslateX = Number(openTopVideoTranslateX);
            openTopVideoTranslatesX.push(openTopVideoTranslateX)
          }
        }
       }
        openTopVideoTranslatesX = openTopVideoTranslatesX.filter((element, index) => openTopVideoTranslatesX.indexOf(element) === index);

        for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
          for(let map_j = 0; map_j < this.mapVideoPoints.length; map_j++){
            if(this.duplicationVideoId[dup_i].open_flg && this.duplicationVideoId[dup_i].video_id == this.mapVideoPoints[map_j].video_id){
              translateXText = this.mapVideoPoints[map_j].transform.match(/translateX.*\)/g)[0];
              translateX = translateXText.match(/[+-]?\d+(?:\.\d+)?/g);
              translateX= Number(translateX);

              for(let open_k = 0; open_k < openTopVideoTranslatesX.length; open_k++){
                if(Math.abs(translateX - openTopVideoTranslatesX[open_k]) % 160 == 0){
                  translateYText = this.mapVideoPoints[map_j].transform.replace(/translateX.*\)/g, '');
                  multiplier = Math.abs(translateX - openTopVideoTranslatesX[open_k]) / 160;
                  addXNum = multiplier * 40;
                  translateX = openTopVideoTranslatesX[open_k] + addXNum;
                  this.mapVideoPoints[map_j].transform = `${translateYText}`;
                  this.mapVideoPoints[map_j].transform +=`translateX(${translateX}px)`
                }
              }
            }
          }
        }
      } else {
        for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
          for(let top_j = 0; top_j < this.topVideoIds.length; top_j++){
            if(this.duplicationVideoId[dup_i].open_flg && this.duplicationVideoId[dup_i].video_id == this.topVideoIds[top_j]){
              openTopVideoIds.push(this.topVideoIds[top_j]);
            }
          }
        }

       for(let map_i = 0; map_i < this.mapVideoPoints.length; map_i++){
        for(let open_j = 0; open_j < openTopVideoIds.length; open_j++){
          if(this.mapVideoPoints[map_i].video_id == openTopVideoIds[open_j]){
            openTopVideoTranslateXText = this.mapVideoPoints[map_i].transform.match(/translateX.*\)/g)[0];
            openTopVideoTranslateX = openTopVideoTranslateXText.match(/[+-]?\d+(?:\.\d+)?/g);
            openTopVideoTranslateX = Number(openTopVideoTranslateX);
            openTopVideoTranslatesX.push(openTopVideoTranslateX)
          }
        }
       }
        openTopVideoTranslatesX = openTopVideoTranslatesX.filter((element, index) => openTopVideoTranslatesX.indexOf(element) === index);

        for(let dup_i = 0; dup_i < this.duplicationVideoId.length; dup_i++){
          for(let map_j = 0; map_j < this.mapVideoPoints.length; map_j++){
            if(this.duplicationVideoId[dup_i].open_flg && this.duplicationVideoId[dup_i].video_id == this.mapVideoPoints[map_j].video_id){
              translateXText = this.mapVideoPoints[map_j].transform.match(/translateX.*\)/g)[0];
              translateX = translateXText.match(/[+-]?\d+(?:\.\d+)?/g);
              translateX= Number(translateX);

              for(let open_k = 0; open_k < openTopVideoTranslatesX.length; open_k++){
                if(Math.abs(translateX - openTopVideoTranslatesX[open_k]) % 40 == 0){
                  translateYText = this.mapVideoPoints[map_j].transform.replace(/translateX.*\)/g, '');
                  multiplier = Math.abs(translateX - openTopVideoTranslatesX[open_k]) / 40;
                  addXNum = multiplier * 160;
                  translateX = openTopVideoTranslatesX[open_k] + addXNum;
                  this.mapVideoPoints[map_j].transform = `${translateYText}`;
                  this.mapVideoPoints[map_j].transform +=`translateX(${translateX}px)`
                }
              }
            }
          }
        }
      }
    },
    resizeWindow(){
      this.mapWidth = this.$refs.map.clientWidth
      this.mapHeight = this.$refs.map.clientHeight
      this.setXYVideoPoint();
      this.setOpenFlg();
    }
  },
  mounted() {
    this.mapWidth = this.$refs.map.clientWidth
    this.mapHeight = this.$refs.map.clientHeight
    window.addEventListener('resize', this.resizeWindow)
  },
  created() {
    if (Object.keys(this.initialInteractionAverageReport).length) {
      this.initialInteractionAverageReport.video_average.map((videoPoint) => {
        this.mapVideoPoints.push({
          transform: `translateY(-17px)translateX(-18px)`
        })
        this.mapVideoPointsHover[videoPoint.video_id] = false
      })
      this.xMin = this.interactionAverageReport.left_line.min
      this.xMax = this.interactionAverageReport.left_line.max
      this.yMin = this.interactionAverageReport.right_line.min
      this.yMax = this.interactionAverageReport.right_line.max
      this.setXYVideoPoint()
    }
  }
}
</script>
<style lang="scss" scoped>
.cursor-default {
  cursor: default !important;
}

.distribution-map {
  margin-top: 40px;

  .title-section {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #333333;
  }

  .video-type {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #999999;
    cursor: pointer;
    z-index: 2;
  }

  .video-own-selected {
    font-weight: 700;
    color: #333333;
  }

  .map-type {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #999999;
  }

  .map-has-title {
    color: #49AB94;
  }

  .map {
    height: 334px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #999999;
    border-bottom: 1px solid #999999;
  }

  .during-verification {
    justify-content: end;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #49AB94;
  }

  .map-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 2px;
    flex-wrap: wrap;

    .bg {
      width: calc(100% / 2 - 1px);
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .avg-txt,
    .avg-right-txt{
      width: 91px;
      position: absolute;
      top: 11px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #49AB94;
    }
    .avg-txt {
      left: 2px;
      transform: rotate(14deg);
    }

    .avg-right-txt{
      right: 2px;
      transform: rotate(-14deg);
    }

    .switch-x {
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      cursor: pointer;
      z-index: 10;
    }

    .switch-y {
      top: 50%;
      left: -8px;
      cursor: pointer;
      z-index: 10;
    }

    .icon-switch-y {
      transform: translateY(-50%) rotate(90deg);
    }

    .btn-txt-x {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 19px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    .btn-txt-y {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 19px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      left: -21px;

      &.another-btn {
        left: -31px;
      }
    }

    .icon-loading-map {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 20;

      .loader {
        border: 2px solid #49ab94;
        border-radius: 50%;
        border-top: 2px solid #ffffff;
        width: 36px;
        height: 36px;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
        margin: auto;
      }

      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .map-video-point {
      height: 30px;
      padding: 2px;
      background-color: #FFFFFF;
      border-radius: 17px;
      filter: drop-shadow(0px 2px 8px rgba(51, 51, 51, 0.1));
      top: 0;
      left: 0;
      z-index: 5;
      transition: transform 1.5s;

      .video-count{
        z-index: 10;
        top: -10px;
        right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #49AB94;
        color: #FFFFFF;
      }

      .map-video-img {
        width: 30px;
        height: 100%;
        background-color: #C4C4C4;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 17px;
      }

      .map-video-info {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .map-video-info-no-title {
        min-width: fit-content;
        height: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #49AB94;
        padding: 8px;
        white-space: nowrap;
        top: -46px;
        right: 50%;
        transform: translateX(50%);
        background-color: #EBEBEB;
        border-radius: 8px;
      }

      .map-video-at-min-left {
        left: 0;
        transform: none;
      }

      .icon-arrow-bottom {
        top: -24px;
        left: 5px;
      }

      .during-verification-tag {
        width: 6px;
        height: 6px;
        top: 1px;
        left: 1px;
        background-color: #49AB94;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
      }
    }

    .delete-shadow{
      z-index: 5;
      filter: none;
    }
    .add-shadow{
      z-index: 6;
      filter: drop-shadow(0px 2px 8px rgba(51, 51, 51, 0.1));
    }

    .top-video-label{
      z-index: 10;
      cursor: pointer;
    }

    .bottom-video-label{
      filter: drop-shadow(0px 2px 8px rgba(51, 51, 51, 0.1));
      top: 10px;
    }

    .map-video-point-title {
      min-width: 140px;
      max-width: 140px;
      padding-right: 8px;
    }

    .map-video-point-hover {
      z-index: 15;
    }
  }

  .btn-1-txt, .btn-2-txt {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #999999;
  }

  .btn-1-txt {
    width: 60px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: -41px;
  }

  .btn-2-txt {
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
  }

  .triangle-x {
    bottom: -5px;
    right: -2px;
  }

  .triangle-y {
    top: -2px;
    left: -5px;
    transform: rotate(-90deg);
  }

  .blank-block {
    width: 11px;
    height: 11px;
    background-color: #FFFFFF;
    left: -1px;
    bottom: -1px;
  }

  .bl-1 {
    border-left: 1px solid #EBEBEB;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .light-green {
    color: #49AB94;
    background-color: #EDF7F4;
  }

  .light-blue {
    background-color: #EDF1F7;
  }

  .light-red {
    color: #CE6060;
    background-color: #FEF4F4;
  }
}

@media screen and (max-width: 1799px) {
  .distribution-map .map-content .avg-txt, 
  .distribution-map .map-content .avg-right-txt {
    top: 12px;
  }

}

@media screen and (max-width: 1700px) {
  .distribution-map .map-content .avg-txt {
    transform: rotate(16deg);
  }

  .distribution-map .map-content .avg-right-txt{
    transform: rotate(-16deg);
  }
}

@media screen and (max-width: 1599px) {
  .distribution-map .map-content .avg-txt {
    top: 15px;
    transform: rotate(19deg);
  }

  .distribution-map .map-content .avg-right-txt{
    top: 15px;
    transform: rotate(-19deg);
  }
}

@media screen and (max-width: 1600px) {
  .distribution-map .map-content .avg-txt {
    top: 16px;
    transform: rotate(20deg);
  }

  .distribution-map .map-content .avg-right-txt{
    top: 16px;
    transform: rotate(-20deg);
  }
}

@media screen and (max-width: 1450px) {
  .distribution-map .map-content .avg-txt {
    top: 17px;
    transform: rotate(21deg);
  }
  .distribution-map .map-content .avg-right-txt{
    top: 17px;
    transform: rotate(-21deg);
  }
}
</style>