<template>
  <div class="intention mb-10">
    <div class="intention-inner">
      <div class="intention-second-inner mt-5" v-if="showIntention">
        <div class="intention-select" v-if="selectedIntention">
          <div class="select" :class="{ 'select-open': selectClickFlg }" @click="clickSelect()" data-compare-intention-graph="action">
            <p class="m-0" :title="selectedIntention.name">{{ selectedIntention.name }}</p>
          </div>
          <div v-if="selectClickFlg" class="intention-option" @click="clickSelect()">
            <div v-for="item in intentions"
                 :key="item.id" class="select-option"
                 :class="{'intention-selected': item.id == selectedIntention.id}"
                 @click="selectIntention(item)"
                 @mouseover="addOptionColor(item.id)">
              <p class="m-0" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="switch-tab ml-5" v-if="hasTwoInteractionButtons">
          <div class="reaction" :class="totalReactionFlg ? 'checked' : 'unchecked'" data-compare-intention-graph="action" @click="checkReaction()">総リアクション
          </div>
          <div class="separate">｜</div>
          <div class="reaction" :class="!totalReactionFlg ? 'checked' : 'unchecked'" data-compare-intention-graph="action" @click="checkReaction()">各リアクション
          </div>
        </div>
      </div>
      <div class="tools mt-4">
        <div class="left-tool">
          <div class="intention-levels" v-if="showIntention">
            <div v-for="item in intentionLevels"
                 :key="item.level"
                 class="level-check"
                 :class="{'disabled': reportIntentionLoading}"
            >
              <i
                class="icon icon-checkbox mr-2"
                :class="[intentionLevels.find(data => data.level === item.level).checkFlg ? 'checked' : '']"
                data-compare-intention-graph="action"
                @click="checkLevel(item.level)"
              ></i>
              <p class="name">{{ item.value }}</p>
            </div>
          </div>
          <div v-if="scrollFlg" class="intention-slider-container">
            <label class="slider-outer"><input type="range" class="intention-slider" min="0" max="100" value="0"
                                               v-model="slider" data-compare-intention-graph="action"></label>
          </div>
        </div>
        <div class="right-tool">
          <div class="switch-image-button" v-if="selectedIntentionMode === intentionModes.chart.value">
            <input type="checkbox" class="switch" v-model="isShowImageIntention" data-compare-intention-graph="action">
            <span class="label">シーン表示</span>
          </div>
        </div>
      </div>
      <intention-percent-chart
        v-if="selectedIntentionMode === intentionModes.percentage.value"
        :videos="checkedVideos"
        :selectedIntention="selectedIntention"
        :selectedLevel="selectedLevels"
        :intentionReport="intentionReport"
        :intentionComment="intentionComment"
        :videoComments="videoComments"
        :intentionCount="intentionCount"
        :reportDataIsLoading="reportIntentionLoading"
        :totalReactionFlg="totalReactionFlg"
        :slider="slider"
        :isShowImageIntention="isShowImageIntention"
        @setScrollFlg="getScrollFlg"
      >
      </intention-percent-chart>
      <intention-line-chart
        v-if="selectedIntentionMode === intentionModes.chart.value"
        :videos="checkedVideos"
        :selectedIntention="selectedIntention"
        :selectedLevel="selectedLevels"
        :intentionSecondReport="intentionSecondReport"
        :intentionComment="intentionComment"
        :videoComments="videoComments"
        :intentionCount="intentionCount"
        :reportDataIsLoading="reportIntentionLoading"
        :totalReactionFlg="totalReactionFlg"
        :slider="slider"
        :isShowImageIntention="isShowImageIntention"
        @setScrollFlg="getScrollFlg"
      >
      </intention-line-chart>
    </div>
  </div>
</template>
<script>
import IntentionPercentChart from "@/components/Top/Step-2/IntentionPercentChart.vue";
import IntentionLineChart from "@/components/Top/Step-2/IntentionLineChart.vue";
import {
  intentionModes,
} from "@/commons/constants";

export default {
  components: {
    IntentionPercentChart,
    IntentionLineChart,
  },
  name: 'IntentionArea',
  props: {
    intentions: Array,
    checkedVideos: Array,
    selectedIntention: Object,
    intentionLevels: Array,
    selectedLevels: Array,
    selectedIntentionMode: Number,
    intentionReport: Array,
    intentionSecondReport: Object,
    intentionComment: Array,
    reportIntentionLoading: Boolean,
    commentIntentionLoading: Boolean,
    allFlg: Number,
    videoComments: Array,
    intentionCount: Array,
    showIntention: Boolean,
  },
  data() {
    return {
      selectClickFlg: false,
      totalReactionFlg: true,
      slider: '0',
      scrollFlg: false,
      intentionModes,
      isShowImageIntention: false,
    }
  },
  computed: {
    hasTwoInteractionButtons() {
      if (this.checkedVideos.length > 0) {
        return Object.keys(this.checkedVideos[0].interaction_btn_2).length > 0;
      }
      return false;
    },
  },
  watch: {
    selectedIntentionMode() {
      this.slider = '0';
    },
  },
  methods: {
    clickSelect() {
      this.selectClickFlg = !this.selectClickFlg;
    },
    addOptionColor(intention_id) {
      let target = document.getElementsByClassName('intention-selected');
      if (intention_id != this.selectedIntention.id) {
        target[0].style.background = '#EBEBEB';
      } else {
        target[0].style.background = '#EDF7F4';
      }
    },
    windowClick() {
      document.addEventListener('click', (e) => {
        if (!e.target.closest('.intention-select')) {
          this.selectClickFlg = false;
        }
      })
    },
    selectIntention(item) {
      this.$emit('setSelectedIntention', item);
    },
    checkLevel(index) {
      if(this.reportIntentionLoading) {
        return;
      }
      this.$emit('setCheckedLevel', index);
    },
    checkReaction() {
      this.totalReactionFlg = !this.totalReactionFlg;
    },
    getScrollFlg(val) {
      this.scrollFlg = val;
    }
  },
  created() {

  },
  mounted() {
    this.windowClick();
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
.intention {
  overflow-x: auto;

  .intention-inner {
    overflow-x: auto;

    .intention-second-inner {
      display: flex;
      align-items: center;

      .intention-select {
        position: relative;
        cursor: pointer;

        .select {
          display: flex;
          align-items: center;
          background-color: #EDF7F4;
          border: none;
          height: 50px;
          padding: 0 20px;
          border-radius: 10px;
          width: 400px;
          -webkit-appearance: none;
          appearance: none;
          background-image: url(/images/svg/icon_select_green_arrow.svg);
          background-size: 15px 10px;
          background-repeat: no-repeat;
          background-position: center right 15px;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 380px;
          }
        }

        .select-open {
          background-image: url(/images/svg/icon_select_green_arrow_up.svg);
          background-size: 30px 22px;
          background-position: center right 7px;
          border-radius: 10px 10px 0 0;
        }

        .intention-option {
          z-index: 6;
          user-select: none;
          border: solid 1px #76c4b5;
          width: 440px;
          padding-top: 49px;
          border-radius: 10px;
          position: absolute;
          top: 0;

          .select-option {
            cursor: pointer;
            display: flex;
            width: 400px;
            height: 40px;
            padding: 0 20px;
            border-bottom: 1px solid #EBEBEB;
            align-items: center;
            background-color: #fff;

            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 380px;
            }

            &.intention-selected {
              background-color: #EDF7F4;
            }

            &:hover {
              background-color: #EDF7F4;
            }

            &:last-child {
              border-radius: 0 0 10px 10px;
            }
          }

          .select-option:last-child {
            border-bottom: none;
          }
        }
      }

      .switch-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 12px;
        }

        .reaction {
          cursor: pointer;
        }

        .separate {
          margin: 0 5px;
        }

        .checked {
          font-weight: bold;
        }

        .unchecked {
          color: #999999;
        }
      }
    }

    .tools {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-tool {
        display: flex;
      }

      .intention-levels {
        display: flex;
        flex-wrap: nowrap;
        color: #49AB94;
        margin-right: 10px;

        .name {
          white-space: nowrap;
          font-size: 15px;
        }

        .level-check {
          margin-right: 20px;
          display: flex;
          flex-shrink: 0;
          align-items: center;

          &.disabled {
            opacity: 0.5;
          }
        }
      }

      .intention-slider-container {
        display: flex;
        align-items: center;

        .slider-outer {
          position: relative;
          display: flex;
          flex-shrink: 0;
          align-items: center;

          &.disabled {
            opacity: 0.5;
          }
        }
        .intention-slider {
          width: 230px;
          appearance: none;
          background-color: #999999;
          height: 1px;
        }

        .intention-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 60px;
          height: 40px;
          border: 0;
          border-radius: 25%;
          background-image: url(/images/svg/icon_scroll.svg);
          background-size: 60px 40px;
          background-position: center center;
          background-repeat: no-repeat;
          cursor: pointer;
          background-color: #fff;
        }

        .intention-slider::-moz-range-thumb {
          width: 60px;
          height: 40px;
          border: 0;
          border-radius: 50%;
          background-image: url(/images/svg/icon_scroll.svg);
          background-size: 60px 40px;
          background-position: center center;
          background-repeat: no-repeat;
          cursor: pointer;
          background-color: #fff;
        }

        .slider-outer:before, .slider-outer:after {
          z-index: -1;
          position: absolute;
          display: inline-block;
          content: '';
          background-color: #999999;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }

        :lang(x)::-moz-placeholder, .slider-outer::before, .slider-outer::after {
          top: 9px;
        }

        .slider-outer:after {
          right: 0;
        }
      }

      .switch-image-button {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        min-width: 120px;
        input.switch {
          position: relative;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          width: 35px !important;
          height: 20px;
          border-radius: 30px;
          cursor: pointer;
          background: #ccc;
          transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
          transition-delay: 0.1s, 0s;
        }
        input.switch:focus {
          border: 0;
          outline: 0;
        }
        input.switch:checked {
          transition-delay: 0s, 0s;
          box-shadow: inset 0 0 0 2.62857em $green !important;
          padding-left: 15px;
        }
        input.switch:before {
          display: inline-block;
          height: 14px;
          width: 14px;
          margin: 3px;
          background-color: #fff;
          border-radius: 2.1em;
          transition: all 0.1s ease 0.1s;
        }
        input.switch:before, input.switch:after {
          content: "";
          display: block;
        }
        input.switch:active::before {
          transition: all 0.1s ease 0.05s;
        }

        .label {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: $black;
          margin-left: 10px;
        }
      }
    }
  }

  .intention-inner::-webkit-scrollbar {
    display: none;
  }

}

@media screen and (max-width: 1600px)  {
  .tools {
    flex-wrap: wrap;
    .left-tool {
      width: 100%;
    }
    .right-tool {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

</style>