<template>
  <div>
    <highcharts
      :options="chartOptions"
      ref="lineChartSummary"
      :constructor-type="'chart'"
      :callback="chartCallback"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import {
  colorInteraction
} from "@/commons/constants";
import {
  getImageTooltipEl,
  lineChartOption,
} from "@/commons/charts";

export default {
  props: {
    video: Object,
    seriesData: Object,
    isTotalReaction: Boolean,
    isShowImageIntention: Boolean,
    maxValue: Number,
    maxDuration: Number,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    seriesData: {
      handler: "handleSeriesDataChange",
      deep: true,
    },
    isTotalReaction: {
      handler: "handleSeriesDataChange",
      deep: true,
    },
    isShowImageIntention: {
      handler: "handleSeriesDataChange",
      deep: true,
    },
  },
  data() {
    const vm = this;
    return {
      chart: null,
      chartOptions: Object.assign({}, lineChartOption, {
        chart: {
          type: "line",
          height: 129,
          width: 252,
          backgroundColor: null,
        },
        xAxis: {
          min: 0,
          tickWidth: 0,
          showLastLabel: true,
          endOnTick: true,
          tickPositioner: function () {
            return [0, vm.maxDuration];
          },
          labels: {
            formatter: function () {
              if(this.value === 0) return this.value;
              return this.value + "s";
            },
            style: {
              fontSize: "8px",
              fontFamily: "Avenir Next",
              fontWeight: 500,
              lineHeight: "12px",
              color: "#999999",
            },
          },
        },
        yAxis: {
          min: 0,
          max: this.maxValue,
          tickPositions: [0, this.maxValue],
          lineWidth: 1,
          gridLineWidth: 0,
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              if(this.value === 0) return '';
              return this.value + "%";
            },
            style: {
              fontSize: "8px",
              fontFamily: "Avenir Next",
              fontWeight: 500,
              lineHeight: "12px",
              color: "#999999",
            },
          },
        },
        plotOptions: {
          ...lineChartOption.plotOptions,
          series: {
            ...lineChartOption.plotOptions.series,
            stickyTracking: false,
          },
        }
      }),
      imageObjects: [],
    };
  },
  methods: {
    handleSeriesDataChange() {
      this.initSeriesData();
      while (this.chart.series.length > 0) {
        this.chart.series[0].remove(false);
      }
      this.chartOptions.series.forEach((data) => {
        this.chart.addSeries(data, false);
      });
      this.$nextTick(() => {
        this.chart.redraw();
        this.clearImages();
        this.addImageToHighestPoint();
      });
    },
    initSeriesData() {
      const series = [];
      if (this.isTotalReaction) {
        const totalData = this.formatDataChart(this.seriesData, "total");
        series.push({
          id: "total-" + this.video.id,
          color: "#000000",
          name: "total-" + this.video.id,
          data: totalData,
          opacity: 0.13,
          states: {
            hover: {
              opacity: 1,
              lineWidth: 2,
            },
          },
          events: this.createSeriesEvents(),
          zIndex: 1,
        });
      } else {
        const leftData = this.formatDataChart(this.seriesData, "left");
        const rightData = this.formatDataChart(this.seriesData, "right");
        series.push(
          {
            id: "left",
            color: colorInteraction[this.video.interaction_btn_1.color]
              ? colorInteraction[this.video.interaction_btn_1.color].color
              : "#000000",
            name: "left",
            data: leftData,
            opacity: 0.5,
            states: {
              hover: {
                opacity: 1,
                lineWidth: 2,
              },
            },
            events: this.createSeriesEvents(),
            zIndex: 1,
          },
          {
            id: "right",
            color: colorInteraction[this.video.interaction_btn_2.color]
              ? colorInteraction[this.video.interaction_btn_2.color].color
              : "#000000",
            name: "right",
            data: rightData,
            opacity: 0.5,
            states: {
              hover: {
                opacity: 1,
                lineWidth: 2,
              },
            },
            events: this.createSeriesEvents(),
            zIndex: 1,
          }
        );
      }

      this.chartOptions.series = series;
    },
    formatDataChart(data, keyName) {
      const series = [];
      for (const key in data) {
        if (isNaN(parseInt(key))) {
          continue;
        }
        series.push({
          x: parseInt(key),
          y: data[key][keyName],
        });
      }
      return series;
    },
    chartCallback(chart) {
      this.chart = chart;
      this.addImageToHighestPoint();
    },
    clearImages() {
      this.imageObjects.forEach((imageObject) => {
        imageObject.destroy();
      });
      this.imageObjects = [];
    },
    createImageObject(data, highestPoint) {
      const vm = this;
      const imgEl = getImageTooltipEl(
        this.video.imageURL + highestPoint.x + ".jpg"
      );

      const imageObject = this.chart.renderer
      .text(
        imgEl,
        highestPoint.plotX + this.chart.plotLeft - 27,
        highestPoint.plotY + this.chart.plotTop - 10,
        true
      )
      .attr({
        cursor: "pointer",
      })
      .addClass(
        "image-highest-point blur" +
        (data.name &&
        (data.name == "left" &&
          vm.seriesData.left_max.value > vm.seriesData.right_max.value) ||
        (data.name == "right" &&
          vm.seriesData.right_max.value > vm.seriesData.left_max.value)
          ? " top"
          : "") + (!vm.isShowImageIntention ? " hide" : "")
      )
      .add();

      imageObject.on("mouseover", function () {
        imageObject.removeClass("blur").addClass("top-hover");
        data.setState("hover");
      });

      imageObject.on("mouseout", function () {
        imageObject.addClass("blur").removeClass("top-hover");
        data.setState("");
      });

      return imageObject;
    },
    createSeriesEvents() {
      const vm = this;
      return {
        mouseOver: function () {
          const seriesIndex = this.index;
          vm.imageObjects[seriesIndex]
          .removeClass("blur")
          .addClass("top-hover");
        },
        mouseOut: function () {
          const seriesIndex = this.index;
          vm.imageObjects[seriesIndex].addClass("blur").removeClass("top-hover");
        },
      };
    },
    addImageToHighestPoint() {
      const series = this.chart.series;
      series.forEach((data) => {
        if (data.data.length === 0) return;
        const highestPoint = data.data.reduce((prev, curr) =>
          curr.y > prev.y ? curr : prev
        );

        if (highestPoint) {
          const imageObject = this.createImageObject(data, highestPoint);
          this.imageObjects.push(imageObject);
        }
      });
    },
  },
  mounted() {
    this.chart = this.$refs.lineChartSummary.chart;
    this.initSeriesData();
    this.chart.redraw();
  },
  created() {
    this.initSeriesData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .image-highest-point {
    display: inline-block;
    overflow: hidden;
    width: 53px !important;
    height: 28px !important;
    border-radius: 5px;
    z-index: 99;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 1);
    }

    img {
      width: 100% !important;
      height: 100% !important;
      display: inline-block;
      position: relative;
    }

    &.top {
      z-index: 100;
    }

    &.top-hover {
      z-index: 101 !important;
    }

    &.blur {
      img {
        opacity: 0.5;
      }
    }

    &.hide {
      display: none;
    }
  }

  .highcharts-container {
    overflow: visible !important;
  }
}
</style>
