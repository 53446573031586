<template>
  <highcharts
      class="mt-2 screening-option"
      :options="chartOptions"
      ref="BasicColumnChart"
      :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import {Chart} from "highcharts-vue";
const jQuery = require("jquery");
import EventBus from "@/commons/event_bus";

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    part: Number,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    answerValueSelected: Array
  },
  watch: {
    step(newVal) {
      let vm = this
      this.maxVal = 0
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? (this.value + 1) * vm.step + (vm.part - 1) * 14 * vm.step : ''
        return this.value % 2 ? (this.value + 1) * newVal : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
      this.setYMax()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    answerValueIdSelected() {
      this.formatDataChartWithStep()
    },
    selectedAnswerId() {
      this.formatDataChartWithStep()
    },
    answerValueSelected() {
      this.maxVal = 0
      this.setYMax()
    }
  },
  data() {
    let vm = this
    return {
      minSecond: 0,
      maxSecond: 13,
      maxVal: 0,
      originSeries: {},
      answerMaxSecond: {},
      chartOptions: {
        chart: {
          height: "340px",
          type: 'column',
          animation: false
        },
        legend: {
          enabled: false
        },
        title: {
          text: ''
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 10
          },
          series: {
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  EventBus.$emit('selectedAnswerChart', e.target.point.series.userOptions.id)
                },
                mouseOver: function() {
                  vm.$nextTick(() => {
                    let elementHover = jQuery('.highcharts-point-hover')
                    elementHover.css('fill', "#49AB94")
                    elementHover.siblings().css('fill', "#49AB94")
                    this.series.data.forEach(function(point) {
                      point.update({
                        opacity: 1
                      })
                    })
                  })
                },
                mouseOut: function() {
                  vm.$nextTick(() => {
                    jQuery('.highcharts-point').removeAttr('style')
                    this.series.data.forEach(function(point) {
                      point.update({
                        opacity: 1
                      })
                    })
                  })
                }
              }
            }
          }
        },
        xAxis: {
          min: -0.5,
          max: 13,
          tickWidth: 0,
          lineColor: '#FFFFFF',
          gridLineColor: '#EBEBEB',
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px'
            },
            formatter: function () {
              if (vm.part > 1) return this.value % 2 ? (this.value + 1) * vm.step + (vm.part - 1) * 14 * vm.step : ''
              return this.value % 2 ? (this.value + 1) * vm.step : ''
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 0,
          tickAmount: 6,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px'
            },
            formatter: function () {
              return this.value ? this.value + '.0%' : ''
            }
          }
        },
        series: []
      },
    };
  },
  methods: {
    initJQuery() {
      let grids = jQuery('.screening-option').find('.highcharts-xaxis-grid').last().children()
      for (let i = 0; i < 14; i++) {
        if (i % 2) grids.eq(i).css("display", "none")
      }

      jQuery('#chartArea .highcharts-point').attr('data-compare-report', 'action')
    },
    initSeriesData() {
      if (this.reportScreening) {
        let seriesData = this.reportScreening.data
        if (seriesData && seriesData.length) {
          seriesData.map(data => {
            data.values.map(item => {
              this.answerMaxSecond[item.screening_answer_id] = 13
              if (data.second > this.answerMaxSecond[item.screening_answer_id]) {
                this.answerMaxSecond[item.screening_answer_id] = data.second
              }
              if (!this.originSeries[item.screening_answer_id]) {
                this.originSeries[item.screening_answer_id] = {}
              }
              this.originSeries[item.screening_answer_id][data.second] = {
                left_count: item.left_count,
                right_count: item.right_count
              }
            })
          })
          this.formatDataChartWithStep()
          this.setYMax()
        }
      }
    },
    formatDataChartWithStep() {
      this.maxSecond = 13
      let series = []
      if (this.answerValueIdSelected && this.answerValueIdSelected.length) {
        this.answerValueIdSelected.map(val => {
          if (this.answerMaxSecond[val] > this.maxSecond) this.maxSecond = this.answerMaxSecond[val]
          let valArr = [], series_key = 0
          if (this.part > 1) series_key += (this.part - 1) * 14 * this.step
          for (let i = 1; i <= 14; i++) {
            if (i % 2 === 0) valArr.push(0)
            else {
              let j = 0, total = 0
              while (j < this.step * 2) {
                if (this.originSeries[val] && this.originSeries[val][series_key] && series_key < this.step * this.part * 14) {
                  total += this.originSeries[val][series_key].left_count + this.originSeries[val][series_key].right_count
                }
                series_key++
                j++
              }
              valArr.push(total)
            }
          }
          series.push({
            id: val,
            color: this.selectedAnswerId == val ? "#49AB94" : "#EDF7F4",
            data: valArr
          })
        })
      } else {
        for (let i = 0; i < 8; i++) {
          let valArr = []
          for (let i = 1; i <= 14; i++) {
            valArr.push(0)
          }
          series.push({data: valArr})
        }
      }
      if (this.answerValueIdSelected) {
        this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.answerValueIdSelected.length
      }
      this.chartOptions.series = series
      this.$nextTick(() => {
        this.initJQuery()
      })
    },
    setYMax() {
      if (this.answerValueSelected) {
        this.answerValueSelected.map(answer => {
          let answerKey = Object.keys(answer)[0]
          let series_key = 0
          if (this.part > 1) series_key += (this.part - 1) * 14 * this.step
          for (let i = 1; i <= 14; i++) {
            let j = 0, total = 0
            while (j < this.step * 2) {
              if (this.originSeries[answerKey] && this.originSeries[answerKey][series_key] && series_key < this.step * this.part * 14) {
                total += this.originSeries[answerKey][series_key].left_count + this.originSeries[answerKey][series_key].right_count
              }
              series_key++
              j++
            }
            if (this.maxVal < total) this.maxVal = total
          }
        })
        if (this.maxVal) {
          this.chartOptions.yAxis.max = this.maxVal
        } else {
          this.chartOptions.yAxis.max = 25
        }
      }
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 14) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    }
  },
  mounted() {
    this.initSeriesData()
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
::v-deep  {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }
}
</style>
