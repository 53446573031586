<template>
  <div
    class="custom-checkbox"
    :style="{ '--custom-color': color }"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.79992 15.905L5.29992 12.405C4.90992 12.015 4.28992 12.015 3.89992 12.405C3.50992 12.795 3.50992 13.415 3.89992 13.805L8.08992 17.995C8.47992 18.385 9.10992 18.385 9.49992 17.995L20.0999 7.40502C20.4899 7.01502 20.4899 6.39502 20.0999 6.00502C19.7099 5.61502 19.0899 5.61502 18.6999 6.00502L8.79992 15.905Z" />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#CCCCCC'
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-checkbox {
  width: 28px;
  height: 28px;
  background-color: #fff;
  border: 1px solid var(--custom-color);
  border-radius: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  svg {
    fill: var(--custom-color);
  }
}
</style>