<template>
  <div class="intention-chart mt-4">
    <div v-if="!reportDataIsLoading && !commentDataIsLoading" class="chart-inner" id="chart-inner">
      <div class="chart-area">
        <div class="left-area">
          <div class="header gray first-header" :class="{'only-header': selectedLevel.length == 0 && !isOnlyComment}">
            {{!isOnlyComment ? "リアクション\\動画名" : '動画名'}}
          </div>
          <template v-if="selectedLevel.length || isOnlyComment">
            <div
                v-for="(level, index) in selectedLevel"
                :key="level.level"
                class="change-area"
                v-show="!isOnlyComment"
                :class="{'last-item': index == selectedLevel.length - 1 && isOnlyReportIntention}"
            >
              <div class="level gray">
                <p class="m-0">{{ level.value }}</p>
              </div>
              <div class="interaction">
                <div class="interaction-name">
                  <div v-if="totalReactionFlg" class="all">
                    <p>合計</p>
                  </div>
                  <div v-if="!totalReactionFlg" class="left-button">
                    <p :title="videos[0].interaction_btn_1.text">
                      {{ videos[0].interaction_btn_1.text }}
                    </p>
                  </div>
                  <div v-if="!totalReactionFlg" class="right-button">
                    <p :title="videos[0].interaction_btn_2.text">
                      {{ videos[0].interaction_btn_2.text }}
                    </p>
                  </div>
                  <div class="all border-top">
                    <p>意向度</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="word-cloud" v-show="!isOnlyReportIntention">
              <div class="word-cloud-inner">
                <p class="title">ワードクラウド</p>
                <div class="select-level" v-show="!isOnlyComment">
                  <p>以下の意向を反映</p>
                  <ul class="word-cloud-select-level">
                    <li v-for="level in selectedLevel" :key="level.level">
                      {{ level.value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="right-area">
          <div v-for="(video, index) in intentionReportData" :key="`video-${index}`" class="interaction-chart">
            <div class="header video-name gray">
              <a :title="video.name" @click="gotoDetailVideo(video.id)" data-compare-intention-graph="action">{{ video.name }}</a>
            </div>
            <template v-if="selectedLevel.length || isOnlyComment">
              <div class="interaction-values" v-show="!isOnlyComment">
                <div class="report-data">
                  <template v-for="(levelData) in listIntentionLevelId">
                    <div class="data-group"
                         v-if="video.report[`level_${levelData}_interactions`]"
                         :key="`data-group-${levelData}`"
                    >
                      <template v-if="video.report[`level_${levelData}_interactions`].values">
                        <div v-if="totalReactionFlg" class="data-raw">
                          <div class="interaction-value"
                               :style="{width: `${video.report[`level_${levelData}_interactions`].values.total}%`}">
                            <p>{{ roundPercent(video.report[`level_${ levelData }_interactions`].values.total) }}%</p>
                          </div>
                        </div>
                        <div v-if="!totalReactionFlg" class="data-raw">
                          <div class="interaction-value left-value"
                               :style="[{width: `${video.report[`level_${levelData}_interactions`].values.left}%`}, setColor(video, 1)]">
                            <p>{{ roundPercent(video.report[`level_${ levelData }_interactions`].values.left) }}%</p>
                          </div>
                        </div>
                        <div v-if="!totalReactionFlg" class="data-raw">
                          <div class="interaction-value right-value"
                               :style="[{width: `${video.report[`level_${levelData}_interactions`].values.right}%`}, setColor(video, 2)]">
                            <p>{{ roundPercent(video.report[`level_${ levelData }_interactions`].values.right) }}%</p>
                          </div>
                        </div>
                        <div class="data-raw">
                          <div class="interaction-value"
                               v-if="video.report[`level_${ levelData }_interactions`].values.count"
                               :style="{width: `${video.report[`level_${levelData}_interactions`].values.count}%`}">
                            <p>{{ roundPercent(video.report[`level_${ levelData }_interactions`].values.count) }}%</p>
                          </div>
                          <div class="interaction-value"
                               v-else-if="video.is_intention == videoIntentionStatus.enabled"
                               :style="{width: `0%`}"
                          >
                            <p>0.0%</p>
                          </div>
                          <div class="interaction-value no-data"
                               v-else
                          >
                            <p>意向を取得していません</p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="totalReactionFlg" class="data-raw">
                          <div class="interaction-value no-data"><p>意向を取得していません</p></div>
                        </div>
                        <div v-if="!totalReactionFlg" class="data-raw">
                          <div class="interaction-value no-data"><p>意向を取得していません</p></div>
                        </div>
                        <div v-if="!totalReactionFlg" class="data-raw">
                          <div class="interaction-value no-data"><p>意向を取得していません</p></div>
                        </div>
                        <div class="data-raw">
                          <div class="interaction-value no-data"><p>意向を取得していません</p></div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              <div class="word-cloud-outer" v-show="!isOnlyReportIntention">
                <div
                  v-if="Object.prototype.hasOwnProperty.call(video, 'comment')"
                  class="word-cloud-iframe"
                  :class="`word-cloud-${video.comment.video_id}`"
                  :data-html="video.comment.word_cloud_html"
                ></div>
                <div v-else
                  class="word-cloud-iframe-empty"
                >
                  <p>コメントを取得していません</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading-report">
      <div class="background">
        <div class="loader"></div>
      </div>
    </div>
    <div id="content-check-bottom"></div>
  </div>
</template>
<script>
import {
  backgroundColorInteraction,
  colorInteraction,
  videoIntentionStatus,
} from "@/commons/constants";
import { intentionWordCloudModify } from "@/commons/mixins";

export default {
  name: 'IntentionPercentChart',
  mixins: [intentionWordCloudModify],
  data() {
    return {
      videoIntentionStatus,
    }
  },
  computed: {
    intentionReportData() {
      let data = JSON.parse(JSON.stringify(this.videos));
      const currentCountData = this.intentionCount.find((data) => {
        return data.intention_id === this.selectedIntention.id;
      });
      data.forEach((video) => {
        video.report = {};
        this.intentionReport.forEach((report) => {
          if (video.id === report.video_id) {
            video.report = report;
            if (video.report.all_interactions) {
              video.report.level_0_interactions = {
                level: 0,
                values: {
                  ...video.report.all_interactions,
                  count: 100,
                },
              };
            }
          }
        });
        // if video not have report data
        if (!Object.keys(video.report).length) {
          this.selectedLevel.forEach((level) => {
            video.report[`level_${ level.level }_interactions`] = {};
          });
        }

        this.intentionComment.forEach((comment) => {
          if (video.id === comment.video_id && video.has_cdai) {
            video.comment = comment;
          }
        });

        // for count intention
        this.videoComments.forEach((comment) => {
          if (video.id === comment.video_id && video.has_cdai && !video.comment) {
            video.comment = comment;
          }
        });

        if (currentCountData && currentCountData.datas && currentCountData.datas.length) {
          const countData = currentCountData.datas.find((item) => {
            return item.video_id === video.id;
          });

          if (countData && countData.count_datas && countData.count_datas.length) {
            this.selectedLevel.forEach((level) => {
              if (Object.keys(video.report[`level_${ level.level }_interactions`]).length) {
                const countValue = countData.count_datas.find((item) => {
                  return item.level === level.level;
                });

                if (countValue) {
                  video.report[`level_${ level.level }_interactions`].values.count = countValue.user_count;
                }
              }
            });
          } else {
            if (video.report['level_0_interactions'] && video.report['level_0_interactions'].values) {
              video.report['level_0_interactions'].values.count = 0;
            }
          }
        }

      });
      return data
    },
  },
  methods: {
    setColor(video, type) {
      if (type === 1) {
        if(!video.interaction_btn_1.color) return
        return {
          'background-color': backgroundColorInteraction[video.interaction_btn_1.color].color,
          'color': colorInteraction[video.interaction_btn_1.color].color,
        }
      } else {
        if(!video.interaction_btn_2.color) return
        return {
          'background-color': backgroundColorInteraction[video.interaction_btn_2.color].color,
          'color': colorInteraction[video.interaction_btn_2.color].color,
        }
      }
    },
    roundPercent(percent) {
      if (typeof percent == "number" && percent < 100) return percent.toFixed(1)
      return percent;
    }
  },
  watch: {
    intentionReport: function () {
      this.initSetSlider();
      this.initVisibleIframe();
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/styles/_intention_chart_top_compare.scss";
.left-area {
  .change-area {
    background-color: #ffffff;

    .level {
      width: 70px;
      border-right: 1px solid #999999;
    }
  }

}
.right-area {
  .data-raw {
    background-color: #fff;
    border-top: 1px solid #999999;

    .interaction-value {
      background-color: #EDF7F4;
      height: 38px;
      display: flex;
      align-items: center;
      color: #49AB94;

      p {
        margin-left: 20px;
        font-weight: bold;
      }
    }

    .no-data {
      background-color: #fff;
      p {
        font-weight: 400;
        color: #999999;
      };
    }
  }
}
</style>