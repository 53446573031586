<template>
  <div>
    <div class="video-capture">
      <div class="video-name">
        <a  class="video-name link" @click="gotoDetailVideo(videoImage.id)"
            data-cut-scene="action"
        >
          {{ videoImage.name }} <i class="icon icon-arrow-right"></i>
        </a>
      </div>
      <div class="images mt-2">
        <div
          class="image-second"
          v-for="(image, index) in listImage"
          :key="'second-' + index"
        >
          <div v-if="Object.keys(image).length">
              <div class="tag-seconds">{{image.second}}s</div>
              <div class="capture" >
                <img class="capture-second" :src="image.image_url" />
                <i
                  class="icon icon-arrow"
                  v-if="(index + step*9) !== videoImage.duration"
                ></i>
              </div>
              <div
                :class="'tag-number ' + classNameTag.rank_2"
                v-if="
                  firstVideo.interaction_btn_1 &&
                  Object.keys(firstVideo.interaction_btn_1).length &&
                  Object.keys(image.rank_good).length
                "
              >
                <div class="ranking">
                  <span>{{ image.rank_good.rank }}</span>
                </div>
                <div class="">{{ image.rank_good.interaction_count && image.rank_good.interaction_count % 1 != 0 ? image.rank_good.interaction_count.toFixed(1) : image.rank_good.interaction_count | numberWithCommas | addPointZero }}%</div>
              </div>
              <div
                :class="'tag-number ' + classNameTag.rank_3"
                v-if="
                  firstVideo.interaction_btn_2 &&
                  Object.keys(firstVideo.interaction_btn_2).length &&
                  Object.keys(image.rank_bad).length
                "
              >
              <div class="ranking">
                <span>{{ image.rank_bad.rank }}</span>
              </div>
              <div class="">{{ image.rank_bad.interaction_count && image.rank_bad.interaction_count % 1 != 0 ? image.rank_bad.interaction_count.toFixed(1) : image.rank_bad.interaction_count | numberWithCommas | addPointZero }}%</div>
              </div>
          </div>
         
         
        </div>
      </div>
    </div>
    <div class="hr" v-if="index !== checkedVideosLength - 1"></div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";
export default {
  setup() {},
  props: {
    videoImage: Object,
    index: Number,
    checkedVideosLength: Number,
    step: Number,
    interactionType: Number,
    firstVideo : Object

  },
  data() {
    return {
      listImage: [],
      duration: null,
      start: null,
      end: null,
    };
  },
  methods: {
    setStartEndImage() {
      if (this.videoImage) {
        let img_show = 9;
        let duration = this.videoImage.duration;
        let url = this.videoImage.image_url;
        let listRankGood = this.videoImage["2_rank"];
        let listRankBad = this.videoImage["3_rank"];
        this.duration = duration;
        this.start = this.step*img_show;
        this.end = this.start + img_show;
        this.listImage = [];
        for (let i = this.start; i < this.end; i++) {
          let second = {};
          let image_url = url + i + ".jpg";
          let rank_good = {};
          let rank_bad = {};
          for (let item in listRankGood) {
            if (listRankGood[item].second == i) {
              rank_good = listRankGood[item];
            }
          }
          for (let item in listRankBad) {
            if (listRankBad[item].second == i) {
              rank_bad = listRankBad[item];
            }
          }
          second["image_url"] = image_url;
          second["rank_good"] = rank_good;
          second["rank_bad"] = rank_bad;
          second["second"] = i;
          if (i <= duration) {
            this.listImage.push(second);
          } else {
            this.listImage.push({});
          }
        }
      }
    },
    gotoDetailVideo(video_id) {
      let top_detail_url = this.$router.resolve({
        name: 'top-detail',
        params: {
          video_id
        }
      });
      window.open(top_detail_url.href, '_blank');
    }
  },
  computed: {
    classNameTag(){
      let color_rank_2 = this.$props.firstVideo.interaction_btn_1 && Object.keys(this.$props.firstVideo.interaction_btn_1).length ? this.$props.firstVideo.interaction_btn_1.color : '';
      let color_rank_3 = this.$props.firstVideo.interaction_btn_2 && Object.keys(this.$props.firstVideo.interaction_btn_2).length ? this.$props.firstVideo.interaction_btn_2.color : '';
      return {
          rank_2 : color_rank_2 == 1 ? 'tag-primary' : color_rank_2 == 2 ? 'tag-nattoku' : color_rank_2 == 3 ? 'tag-danger' : color_rank_2 == 4 ? 'tag-suki' : '',
          rank_3 : color_rank_3 == 1 ? 'tag-primary' : color_rank_3 == 2 ? 'tag-nattoku' : color_rank_3 == 3 ? 'tag-danger' : color_rank_3 == 4 ? 'tag-suki' : '',
      }
    },
  },
  watch: {
    videoImage: function(video) {
      if (video) {
        let start = 0;
        let img_show = 9;
        let duration = this.videoImage.duration;
        let url = this.videoImage.image_url;
        let listRankGood = this.videoImage["2_rank"];
        let listRankBad = this.videoImage["3_rank"];
        this.duration = duration;
        this.start = start;
        this.end = start + img_show;
        this.listImage = [];

        for (let i = this.start; i < this.end; i++) {
          let second = {};
          let image_url = url + i + ".jpg";
          let rank_good = {};
          let rank_bad = {};
          for (let item in listRankGood) {
            if (listRankGood[item].second == i) {
              rank_good = listRankGood[item];
            }
          }
          for (let item in listRankBad) {
            if (listRankBad[item].second == i) {
              rank_bad = listRankBad[item];
            }
          }
          second["image_url"] = image_url;
          second["rank_good"] = rank_good;
          second["rank_bad"] = rank_bad;
          second["second"] = i;
          if (i <= duration) {
            this.listImage.push(second);
          } else {
            this.listImage.push({});
          }
        }
      }
    },
    step: function () {
      this.setStartEndImage()
    }
  },
  mounted(){
    this.setStartEndImage()
  },
  created() {
    EventBus.$on("setStartEndImage", this.setStartEndImage);
  },
  destroyed() {
    EventBus.$off("setStartEndImage", this.setStartEndImage);
  },
};
</script>
<style lang="scss" scoped>
  .video-name.link {
    cursor: pointer;
  }
</style>
