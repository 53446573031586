<template>
  <div>
    <highcharts
      v-show="!questionIdSelected"
      :id="isArea ? 'chart-container-2' : 'chart-container-1'"
      class="mt-2"
      :options="chartOptions"
      ref="lineChartSummary"
      :constructor-type="'chart'"
    ></highcharts>
    <column-chart
      v-show="questionIdSelected"
      :reportScreening="reportScreening"
      :answerValueIdSelected="answerValueIdSelected"
      :selectedAnswerId="selectedAnswerId"
      :step="step"
      :part="part"
      :answerValueSelected="answerValueSelected"
    ></column-chart>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import ColumnChart from '@/components/Charts/Column.vue'
import {
  getXColumnsByStep,
  formatDataChartWithStep,
  lineChartOption,
  getReactionTagTooltipEl,
  getReactionTagTooltipElStep1,
  getImageTooltipEl,
  getTooltipLineChart,
  getYMaxSummaryChart,
  getTooltipLineChartStep1,
} from '@/commons/charts'
const jQuery = require('jquery')
import EventBus from '@/commons/event_bus'
import { colorInteraction } from '@/commons/constants'

export default {
  props: {
    changeStepChart: String,
    seriesData: Array,
    allSeriesData: Array,
    selectedId: String,
    dataReport: Object,
    imageURL: String,
    step: Number,
    part: Number,
    column: Number,
    maxColumn: Number,
    lineChart: String,
    interactionType: Number,
    nameChart: String,
    project: Object,
    isArea: Boolean,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    questionIdSelected: [Number, String],
    answerValueSelected: Array,
    dynamicButton: { type: Boolean, default: false },
    isShared: { type: Boolean, default: false },
    numberElmTooltip: { type: Number, default: 2 }, // Number elements of tooltip
    showListVideo: { type: Boolean, default: true },
  },
  components: {
    highcharts: Chart,
    ColumnChart,
  },
  watch: {
    step: function (newValue) {
      this.chartOptions.xAxis.tickInterval = newValue
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0),
      }
      this.prevSelected = this.currentSelected
      this.currentSelected = 0
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.tickInterval = newValue
      this.chartOptions.xAxis.max = newValue * this.maxColumn
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue
      this.prevSelected = this.currentSelected
      this.currentSelected = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.min = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.tickInterval = this.step
      this.chartOptions.xAxis.max = (newValue - 1) * this.step * this.column + this.step * this.maxColumn
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * this.column),
      }
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
    },
    seriesData: {
      handler: function () {
        this.initSeriesData()
        while (this.chart.series.length > 0) this.chart.series[0].remove(false)
        this.chartOptions.series.forEach(serie => {
          this.chart.addSeries(serie, false)
        })
        this.$nextTick(() => {
          this.chart.redraw()
          if (this.selectedId) {
            this.loaded(this.chart)
          }
        })
      },
      deep: true,
    },
    selectedId: function () {
      if (this.chart.xAxis[0].options.plotLines && this.chart.xAxis[0].options.plotLines.length) {
        this.chart.xAxis[0].removePlotLine('plotLineTooltip')
        this.chart.xAxis[0].update()
      }
      this.clearTooltip()
      this.resetToolTip()
      this.initSeriesData()
      this.chart.series.forEach(serie => {
        serie.update(
          this.chartOptions.series.find(e => e.name == serie.name),
          false,
        )
      })
      this.$nextTick(() => {
        this.chart.redraw()
      })
      this.loaded(this.chart)
    },
    imageURL: function () {
      this.clearTooltip()
      this.loaded(this.chart)
    },
    showListVideo: function () {
      this.loaded(this.chart)
    },
  },
  computed: {
    tick: {
      get: function () {
        return this.chartOptions.xAxis.tickInterval
      },
      set: function (value) {
        this.chartOptions.xAxis.tickInterval = value
      },
    },

    reportVideo() {
      return this.seriesData.filter(e => (e.mainId ? e.mainId == this.selectedId : e.id == this.selectedId))
    },

    arrIdSelected() {
      return this.reportVideo.map(item => item.id)
    },
  },
  data() {
    let currentSelected = 0
    let prevSelected = 0
    let prevStep = 1
    let tooltipEl, tooltipEl2
    let line, image, image2, clickX, clickY
    let currentWidth
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption))
    chartOptions.yAxis.max = 10
    let vm = this
    let list_avg_name_chart = ['avg', 'avg-0-15', 'avg-16-30', 'avg-31-60', 'avg-61']
    if (
      vm.selectedId != 'avg' &&
      vm.selectedId != 'avg-0-15' &&
      vm.selectedId != 'avg-16-30' &&
      vm.selectedId != 'avg-31-60' &&
      vm.selectedId != 'avg-61'
    ) {
      //show plotLine only active line not is avg
      chartOptions.xAxis.plotLines = [
        {
          className: 'line-show-tooltip',
          color: '#999999',
          width: 1,
          value: currentSelected,
          zIndex: 2,
        },
      ]
    }
    // add tooltip for avg line
    chartOptions.tooltip = {
      useHTML: true,
      enabled: true,
      backgroundColor: '#ebebeb',
      borderRadius: '8',
      borderColor: 'transparent',
      shadow: false,
      style: {
        color: '#49ab94',
        fontSize: '10px',
        lineHeight: '16px',
        fontFamily: 'Noto Sans JP',
        fontWeight: '400',
      },
      formatter() {
        if (list_avg_name_chart.includes(this.series?.name)) {
          return `<span>&nbsp;${Math.round(this.y * 100) / 100}%</span>`
        } else return false
      },
    }

    return {
      chart: null,
      currentSelected,
      prevSelected,
      prevStep,
      tooltipEl,
      tooltipEl2,
      line,
      currentWidth,
      image,
      image2,
      clickX,
      clickY,
      prevPart: 1,
      chartOptions: {
        chart: {
          animation: false,
          height: '340px',
          events: {
            load: function (e) {
              vm.load(e)
            },
            redraw: function (e) {
              vm.redraw(e)
            },
          },
        },
        ...chartOptions,
      },
      positionTooltip: 0,
    }
  },
  methods: {
    getXMax(chart) {
      let arrChartsSelected = []
      this.arrIdSelected.forEach(idSelected => {
        arrChartsSelected = [...arrChartsSelected, ...chart.get(idSelected).xData]
      })
      return Math.max(...arrChartsSelected)
    },
    selectedChart(name) {
      EventBus.$emit('selectedChart', name)
    },
    hoverLine(e) {
      if (e.target.series) {
        let id = e.target.series.id
        this.chart.series.forEach(serie => {
          if (serie.id == id) {
            serie.setState('hover')
          } else {
            serie.setState('')
          }
        })
        if (id) {
          this.$emit('addClassActive', id)
        }
      }
    },
    mouseOut(e) {
      if (e.target.series) {
        let name = e.target.series.name
        if (name) {
          //remove class active
          this.$emit('removeClassActive', name)
        }
      }
    },
    initSeriesData() {
      let series = []
      let yMax = getYMaxSummaryChart(this.allSeriesData ?? this.seriesData)
      let vm = this
      let list_avg_id = ['avg', 'avg-0-15', 'avg-16-30', 'avg-31-60', 'avg-61']
      this.seriesData.forEach(serieData => {
        let { data, id, mainId, colorLine } = serieData
        let active = mainId ? this.selectedId == mainId : this.selectedId === id
        let color = {}
        if (this.isArea || this.isShared) {
          color = colorInteraction[colorLine]
        } else {
          color = { color: '#49AB94' }
        }
        let serie = {
          className: 'chart-line',
          mainId: mainId,
          id,
          ...(list_avg_id.includes(serieData.id)
            ? {
                color: '#333333',
              }
            : color),
          name: serieData.id,
          data: formatDataChartWithStep(data),
          zoneAxis: 'x',
          zones: [
            {
              value: this.step * this.part * this.column,
            },
            {
              dashStyle: 'ShortDash',
            },
          ],
          opacity: active ? 1 : 0.25,
          findNearestPointBy: 'xy',
          states: {
            hover: {
              opacity: 1,
              lineWidth: 2,
            },
            ...(active
              ? {
                  inactive: {
                    opacity: 1,
                  },
                }
              : {}),
          },
          point: {
            events: {
              mouseOver: this.hoverLine,
              mouseOut: this.mouseOut,
            },
          },
          events: {
            click: function () {
              let ids
              if (list_avg_id.includes(this.name)) {
                ids = this.userOptions?.mainId ? this.userOptions?.mainId : this.name
              } else {
                ids = this.userOptions?.mainId ? this.userOptions?.mainId.split('-')[1] : this.name.split('-')[1]
              }
              let selected = ids
              vm.selectedChart(selected)
            },
          },
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null,
            symbol: 'circle',
            radius: 5,
            enabled: false,
          },
          zIndex: 1,
        }
        if (active && !list_avg_id.includes(this.selectedId)) {
          let current = serie.data.find(e => e.x == this.currentSelected)
          if (current) {
            current.marker = { enabled: true }
          }
        } else if (active && list_avg_id.includes(this.selectedId)) {
          serie.zIndex = 2
        } else {
          serie.data.map(e => {
            e.marker = { enabled: false }
            return e
          })
        }
        series.push(serie)
      })
      this.chartOptions.yAxis.max = yMax + 10
      this.chartOptions.series = series

      this.$nextTick(function () {
        jQuery('#chartArea .chart-line').attr('data-compare-report', 'action')
      })
    },
    resetToolTip() {
      this.tooltipEl = null
      this.image = null
      this.image2 = null
    },
    clearTooltip() {
      if (this.tooltipEl) {
        this.tooltipEl.destroy()
        this.tooltipEl = null
      }
      if (this.image) {
        this.image.destroy()
        this.image = null
      }
      if (this.image2) {
        this.image2.destroy()
        this.image2 = null
      }
    },
    load(e) {
      let chart = e.target
      this.chart = chart
      this.loaded(chart)
    },
    loaded(chart) {
      if (
        this.selectedId &&
        this.selectedId != 'avg' &&
        this.selectedId != 'avg-0-15' &&
        this.selectedId != 'avg-16-30' &&
        this.selectedId != 'avg-31-60' &&
        this.selectedId != 'avg-61'
      ) {
        // let videoId = this.selectedId.split("-")[1];
        // let reportVideo = this.dataReport.report_data.find(
        //   (e) => e.video_id == videoId
        // );
        if (this.reportVideo) {
          if (this.getXMax(chart) < this.currentSelected) {
            this.currentSelected = this.step * this.column * (this.part - 1)
          }
          chart.xAxis[0].min = chart.xAxis[0].min < 0 ? 0 : chart.xAxis[0].min
          let numberColumn = (this.currentSelected - chart.xAxis[0].min) / this.step
          let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
          let yMax = chart.yAxis[0].max
          let currentX = this.currentSelected
          if (chart.get(this.arrIdSelected[0])?.yData?.length > currentX) {
            let numberMarker = 1
            if (this.isShared) {
              numberMarker = this.numberElmTooltip
            }
            let positionYMax = Number.MAX_VALUE
            for (let i = 1; i <= numberMarker; i++) {
              chart.get(this.arrIdSelected[i - 1]).data.map(e => {
                if (e.x != this.currentSelected) {
                  e.marker = {}
                } else {
                  e.marker = { enabled: true }
                }
                return e
              })
              let currentY = chart.get(this.arrIdSelected[i - 1]).yData[currentX]
              let positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 69
              if (positionY < 50) {
                positionY = chart.plotHeight - (currentY / yMax) * chart.plotHeight + 30
              }
              if (positionY < positionYMax) positionYMax = positionY
            }

            if (chart.xAxis[0].options.plotLines && chart.xAxis[0].options.plotLines.length) {
              chart.xAxis[0].options.plotLines[0].value = this.currentSelected
            } else {
              chart.xAxis[0].addPlotLine({
                className: 'line-show-tooltip',
                color: '#999999',
                width: 1,
                value: this.currentSelected,
                id: 'plotLineTooltip',
              })
            }

            chart.xAxis[0].update()
            this.$nextTick(() => {
              this.clearTooltip()
              this.drawTooltipSVG(chart, positionX, positionYMax)
              let gridBox = chart.xAxis[0].gridGroup.getBBox()
              let widthColumn = gridBox.width / this.column
              let currentSeries = this.chartOptions.series.filter(e =>
                e.mainId ? e.mainId == this.selectedId : e.name == this.selectedId,
              )
              let currentSeriesData = []
              currentSeries.forEach(serie => {
                currentSeriesData = [...currentSeriesData, ...serie.data]
              })
              let maxChart = Math.max(...currentSeriesData.map(e => e.x))
              let currentVideoMaxX = maxChart < chart.xAxis[0].max ? maxChart : chart.xAxis[0].max - this.step / 2
              if (numberColumn == Math.floor((currentVideoMaxX - (this.part - 1) * this.column) / this.step)) {
                this.image = null
              } else {
                this.image = chart.renderer
                  .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn, 10, 28, 16)
                  .attr({
                    zIndex: 100,
                    cursor: 'pointer',
                    'data-compare-report': 'action',
                  })
                  .translate(0, 0)
                  .addClass('image-plot-line image-plot-line-next')
                  .on('mousedown', this.start)
                  .add()
                this.image.on('mousedown', this.start)
              }
              if (numberColumn > 0) {
                this.image2 = chart.renderer
                  .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
                  .attr({
                    zIndex: 100,
                    cursor: 'pointer',
                    'data-compare-report': 'action',
                  })
                  .translate(0, 0)
                  .addClass('image-plot-line image-plot-line-prev')
                  .on('mousedown', this.start)
                  .add()
                this.image2.on('mousedown', this.start)
              }
              this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
            })
          } else {
            this.clearTooltip()
          }
        }
      } else {
        this.currentSelected = this.step * this.column * (this.part - 1)
      }
    },
    redraw(e) {
      let chart = e.target
      this.chart = chart
      if (chart.oldChartWidth && chart.chartWidth != this.oldChartWidth) {
        this.oldChartWidth = chart.chartWidth
        this.reflow(chart)
      } else if (this.prevStep != this.step || (this.prevPart && this.prevPart != this.part)) {
        this.prevStep = this.step
        this.prevPart = this.part
        this.loaded(chart)
      }
    },
    start(e) {
      let chart = this.chart
      jQuery(document).bind({
        'mousemove.image-plot-line': this.steps,
        'mouseup.image-plot-line': this.stop,
      })
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let numberColumn = (this.currentSelected - chart.xAxis[0].min) / this.step
      if (jQuery(e.target).hasClass('image-plot-line-next')) {
        if (this.image) {
          this.image.destroy()
          this.image = null
        }
        this.image = chart.renderer
          .image('/images/svg/icon_next_line_hover.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      } else {
        if (this.image2) {
          this.image2.destroy()
          this.image2 = null
        }
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line_hover.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      }
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      this.clickX = e.pageX - this.line.translateX
    },
    steps(e) {
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let currentVideoMaxX = this.getXMax(chart) < chart.xAxis[0].max ? this.getXMax(chart) : chart.xAxis[0].max
      let maxWidthExtra = (widthColumn * (currentVideoMaxX - this.currentSelected)) / this.step
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      let maxWidthMinus = widthColumn * numberColumn
      let translateX = e.pageX - this.clickX
      if (maxWidthMinus + translateX < 0) return
      if (translateX > maxWidthExtra) return

      if (this.line) {
        this.line.translate(translateX)
      }
      if (this.image) {
        this.image.translate(translateX)
      }
      if (this.image2) {
        this.image2.translate(translateX)
      }
      this.tooltipEl.attr('x', this.positionTooltip + translateX)
    },
    stop() {
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      this.prevSelected = this.currentSelected
      this.currentSelected =
        this.currentSelected + Math.round((this.line.translateX / chart.plotWidth) * this.column) * this.step
      if (this.currentSelected > this.getXMax(chart)) {
        this.currentSelected = this.currentSelected - this.step
      }
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      jQuery(document).unbind('.image-plot-line')

      let numberMarker = 1
      if (this.isShared) {
        numberMarker = this.numberElmTooltip
      }

      this.clearTooltip()
      let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
      let yMax = chart.yAxis[0].max

      let positionYMax = Number.MAX_VALUE
      for (let i = 1; i <= numberMarker; i++) {
        chart.get(this.arrIdSelected[i - 1]).data.map(e => {
          if (e.x != this.currentSelected) {
            e.marker = {}
          } else {
            e.marker = { enabled: true }
          }
          return e
        })
        let currentY = chart.get(this.arrIdSelected[i - 1]).yData[this.currentSelected / this.step]
        let positonY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 69
        if (positonY < 60) {
          positonY = chart.plotHeight - (currentY / yMax) * chart.plotHeight + 30
        } else if (positonY > 237) {
          positonY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 62
        }
        if (positonY < positionYMax) positionYMax = positonY
      }

      this.drawTooltipSVG(chart, positionX, positionYMax)

      chart.xAxis[0].options.plotLines[0].value = this.currentSelected
      chart.xAxis[0].update()
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      if (numberColumn != 0) {
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      } else {
        this.image2 = null
      }

      let currentVideoMaxX =
        this.getXMax(chart) < chart.xAxis[0].max ? this.getXMax(chart) : chart.xAxis[0].max - this.step / 2
      if (numberColumn >= Math.floor((currentVideoMaxX - (this.part - 1) * this.column * this.step) / this.step)) {
        this.image = null
      } else {
        this.image = chart.renderer
          .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      }
    },
    reflow(chart) {
      if (
        (this.selectedId &&
          this.selectedId != 'avg' &&
          this.selectedId != 'avg-0-15' &&
          this.selectedId != 'avg-16-30' &&
          this.selectedId != 'avg-31-60' &&
          this.selectedId != 'avg-61') ||
        this.selectedId == null
      )
        return
      // let videoId = this.selectedId.split("-")[1];
      // let reportVideo = this.dataReport.report_data.find(
      //   (e) => e.video_id == videoId
      // );

      if (!this.reportVideo) return
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column) / this.step
      if (this.tooltipEl) {
        this.tooltipEl.destroy()
        this.tooltipEl = null
      }
      this.clearTooltip()
      if (numberColumn != 0) {
        this.image2 = chart.renderer
          .image(
            '/images/svg/icon_prev_line.svg',
            chart.xAxis[0].gridGroup.getBBox().x + numberColumn * widthColumn - 30,
            10,
            28,
            16,
          )
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      } else {
        this.image2 = null
      }
      let currentVideoMaxX =
        this.getXMax(chart) < chart.xAxis[0].max ? this.getXMax(chart) : chart.xAxis[0].max - this.step / 2
      if (numberColumn == Math.floor((currentVideoMaxX - (this.part - 1) * this.column) / this.step)) {
        this.image = null
      } else {
        this.image = chart.renderer
          .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-compare-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      }
      let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
      let yMax = chart.yAxis[0].max

      let numberMarker = 1
      if (this.isShared) numberMarker = this.numberElmTooltip
      let positionYMax = Number.MAX_VALUE
      for (let i = 1; i <= numberMarker; i++) {
        let currentY = chart.get(this.arrIdSelected[i - 1]).yData[this.currentSelected]
        let positonY = chart.plotHeight - (currentY / yMax) * chart.plotHeight - 69
        if (positonY < 20) {
          positonY = chart.plotHeight - (currentY / yMax) * chart.plotHeight + 30
        }
        if (positonY < positionYMax) positionYMax = positonY
      }

      if (chart.xAxis[0].plotLinesAndBands[0]) {
        this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      }
      this.drawTooltipSVG(chart, positionX, positionYMax)
    },
    drawTooltipSVG(chart, positionX, positonY) {
      let data = {}
      if (this.lineChart == 'chartVideo') {
        let videoId = this.selectedId.split('-')[1]
        let reportVideo = this.dataReport.report_data.find(e => e.video_id == videoId)
        data = (reportVideo && reportVideo.tally_result.find(e => e.second == this.currentSelected)) || {
          left: 0,
          right: 0,
        }
      } else if (this.lineChart == 'chartAttribute') {
        let attribute_value_id = this.selectedId.split('-')[1]
        let dataSecond = []
        if (this.dataReport && this.dataReport.data && this.dataReport.data[this.currentSelected]) {
          dataSecond = this.dataReport.data[this.currentSelected].values
        }
        let dataAttributeValue = dataSecond.find(e => e.attribute_value_id == attribute_value_id)

        if (!this.dynamicButton) {
          if (dataAttributeValue) {
            data = { left: dataAttributeValue['left_count'], right: dataAttributeValue['right_count'] }
          } else {
            data = { left: 0, right: 0 }
          }
        } else {
          for (let i = 1; i <= this.numberElmTooltip; i++) {
            // data = { left: dataAttributeValue['left_count'], right: dataAttributeValue['right_count'] };
            data[`btn${i}`] = dataAttributeValue ? dataAttributeValue[`btn${i}`] : 0
          }
        }
      }
      let imgEl = getImageTooltipEl(this.imageURL + this.currentSelected + '.jpg')
      if (!this.dynamicButton) {
        let reactionTagsEl = getReactionTagTooltipEl(
          this.project,
          this.nameChart == 'Percentage'
            ? (data = {
                left: Math.abs(data.left) + '%',
                right: Math.abs(data.right) + '%',
                second: data.second,
              })
            : data,
        )
        let tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
        this.tooltipEl = chart.renderer
          .text(tooltipEl, positionX, positonY, true)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
          })
          .add()
        this.positionTooltip = positionX
      } else {
        let cloneData = {}
        if (this.nameChart == 'Percentage') {
          for (let i = 1; i <= this.numberElmTooltip; i++) {
            cloneData[`btn${i}`] = Math.abs(data[`btn${i}`]) + '%'
          }
          cloneData.second = data.second
        } else cloneData = data

        let reactionTagsEl = getReactionTagTooltipElStep1(this.project, cloneData)
        let tooltipEl = getTooltipLineChartStep1(imgEl, reactionTagsEl)
        this.tooltipEl = chart.renderer
          .text(tooltipEl, positionX, positonY, true)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
          })
          .add()
        this.positionTooltip = positionX
      }
    },
  },
  mounted() {
    this.chartOptions.xAxis.min = (this.part - 1) * this.step * this.column
    this.chartOptions.xAxis.tickInterval = this.step
    this.chartOptions.xAxis.max = (this.part - 1) * this.step * this.column + this.step * this.maxColumn
    this.chart = this.$refs.lineChartSummary.chart
    this.$emit('setChart', this.chart)
    this.initSeriesData()
    this.chart.redraw()
  },
  created() {
    this.initSeriesData()
  },
}
</script>
<style lang=""></style>
